import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  List,
  Typography,
  message,
  Popconfirm,
} from "antd";
import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  addInventoryCategoryApi,
  deleteInventoryCategoryApi,
  getInventoryCategoriesListApi,
  updateInventoryCategoryApi,
} from "../../../services/inventory.services";
const CategoryList = () => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [categories, setCategories] = useState();

  useEffect(() => {
    fetchCategoriesList();
  }, []);

  const fetchCategoriesList = async () => {
    try {
      const resp = await getInventoryCategoriesListApi();
      setCategories(resp?.data || []);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    form.resetFields();

    setIsModalOpen(false);
    setEdit(false);
    setSelectedCategory(null);
  };

  const handleEdit = (item) => {
    setEdit(true);
    setSelectedCategory(item);
    form.setFieldsValue({ name: item.name });
    showModal();
  };

  const handleDelete = async (id) => {
    try {
      const res = await deleteInventoryCategoryApi(id);
      if (res) {
        message.success("Category deleted successfully!");
        fetchCategoriesList();
      }
    } catch (error) {
      console.error("Error deleting category:", error);
      message.error(error?.response?.data?.message);
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (edit && selectedCategory) {
        const response = await updateInventoryCategoryApi(
          selectedCategory?._id,
          values
        );
        if (response) {
          message.success("Category updated successfully!");
          fetchCategoriesList();
        }
      } else {
        const response = await addInventoryCategoryApi(values);
        if (response) {
          message.success("Category added successfully!");
          fetchCategoriesList();
        }
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
      console.error("Error adding category:", error);
    } finally {
      handleCancel();
    }
  };

  return (
    <Card style={{ width: "75%" }} styles={{ body: { padding: 0 } }}>
      <List
        bordered
        header={
          <div style={{ textAlign: "right", paddingTop: "5px" }}>
            <Button
              type="primary"
              onClick={() => {
                setEdit(false);
                showModal();
              }}
            >
              <PlusOutlined /> Add Category
            </Button>
          </div>
        }
        size="medium"
        dataSource={categories}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button type="text" onClick={() => handleEdit(item)}>
                <EditOutlined />
              </Button>,
              <Popconfirm
                title="Are you sure to delete this category?"
                onConfirm={() => handleDelete(item._id)}
                onCancel={(e) => e.stopPropagation()}
                okText="Yes"
                cancelText="No"
              >
                <Button type="text" >
                  <DeleteOutlined />
                </Button>
              </Popconfirm>,
            ]}
          >
            <Typography.Text>{item.name}</Typography.Text>
          </List.Item>
        )}
      />
      <Modal
        title={edit ? "Edit Category" : "Add Category"}
        footer={null}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Form.Item
            label="Category Name"
            name="name"
            rules={[{ required: true, message: "Category name is required!" }]}
          >
            <Input placeholder="Enter Category Name" />
          </Form.Item>
          <Form.Item style={{ textAlign: "right" }}>
            <Button
              type="default"
              style={{ marginRight: "10px" }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              {edit ? "Update" : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default CategoryList;
