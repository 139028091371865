import {
  Card,
  Col,
  List,
  Row,
  Collapse,
  theme,
  Table,
  Tag,
  Typography,
} from "antd";
import React from "react";
import { DownOutlined } from "@ant-design/icons";
import "../chat.css";

const { useToken } = theme;

const maintenanceCost = [
  {
    title: <p>Asset Name</p>,
    dataIndex: "asset_name",
    key: "asset_name",
    render: (val, record) =>
      val ? (
        <span>
          {val} <Tag color="geekblue">{record?.asset_code}</Tag>
        </span>
      ) : (
        "-"
      ),
  },
  {
    title: <p>Total Maintenance Cost</p>,
    dataIndex: "total_maintenance_cost",
    key: "total_maintenance_cost",
    render: (val) => val || "-",
  },
  {
    title: <p>Average Maintenance Cost</p>,
    dataIndex: "avg_maintenance_cost",
    key: "avg_maintenance_cost",
    render: (val) => val || "-",
  },
];

const InsightCards = ({ insightsData }) => {
  const { token } = useToken();

  const panelStyle = {
    marginBottom: 24,
    padding: "8px",
    background: "#fff",
    borderRadius: token.borderRadiusLG,
    border: "none",
    boxShadow: "2px 3px 8px rgb(223, 223, 223)",
  };

  const FFCard = () => (
    <Card className="insights-cards" styles={{body:{ padding: 0} }}>
      <List
        dataSource={insightsData?.failed_assets}
        renderItem={(item) => (
          <List.Item style={{ padding: "10px 15px" }}>
            <Typography.Text>Asset: {item?.asset_name} (Code: {item?.asset_code}) has failed {item?.failed_counts} times in the last 30 days, accounting for 594.13% of your total plant downtime.</Typography.Text>
          </List.Item>
        )}
      />
    </Card>
  );

  const MCCard = () => (
    <Card className="insights-cards" styles={{body:{ padding: 0} }}>
      <Table
        columns={maintenanceCost}
        dataSource={insightsData?.maintenance_costs}
        pagination={false}
        scroll={{ y: 250 }}
      />
    </Card>
  );

  const PMCard = () => (
    <Card className="insights-cards" styles={{body:{ padding: 0} }}>
      <List
        dataSource={insightsData?.preventive_maintenance}
        renderItem={(item) => (
          <List.Item style={{ padding: "10px 15px" }}>
            <Typography.Text>
              Asset "{item?.asset_name}" has observed {item?.breakdown_count}{" "}
              breakdowns in the last month even though {item?.pm_count}{" "}
              preventive maintenances were done.
            </Typography.Text>
          </List.Item>
        )}
      />
    </Card>
  );

  const collapseItems = [
    {
      key: "1",
      label: (
        <Typography.Text style={{ fontWeight: 600, color: "#4d4d4d" }}>
          Top 5 Assets with Frequent Failures in the Last 1 Month
        </Typography.Text>
      ),
      children: <FFCard />,
      style: panelStyle,
    },
    {
      key: "2",
      label: (
        <Typography.Text style={{ fontWeight: 600, color: "#4d4d4d" }}>
          Top 5 Assets with the Highest Maintenance Costs
        </Typography.Text>
      ),
      children: <MCCard />,
      style: panelStyle,
    },
    {
      key: "3",
      label: (
        <Typography.Text style={{ fontWeight: 600, color: "#4d4d4d" }}>
          Top 5 Underperforming Assets with Frequent Breakdown despite
          Preventive Maintenances in the Last One Month
        </Typography.Text>
      ),
      children: <PMCard />,
      style: panelStyle,
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <DownOutlined rotate={isActive ? 0 : -90} />
          )}
          style={{ background: "transparent" }}
          items={collapseItems}
          defaultActiveKey={[1]}
        />
      </Col>
    </Row>
  );
};

export default InsightCards;
