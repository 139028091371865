import { SearchOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Empty,
  Form,
  Input,
  message,
  Result,
  Row,
  Select,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";

import {
  getStoreByInventoryApi,
  getStoreListApi,
  searchStoresByInventoryApi,
} from "../../../services/inventory.services";

const StoresList = () => {
  const [storeList, setStoreList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchStoreOptions = useCallback(
    debounce(async (query) => {
      if (!query) return;
      try {
        const res = await searchStoresByInventoryApi({ name: query });
        setOptions(
          res?.data?.map((item) => ({
            label: item?.name,
            value: item?._id,
          })) || []
        );
      } catch (e) {
        console.error(e);
        message.error("Failed to fetch search results");
      }
    }, 500),
    []
  );

  useEffect(() => {
    fetchStoreOptions(searchValue);
  }, [searchValue, fetchStoreOptions]);

  useEffect(() => {
    selectedInventory ? fetchStoreList() : fetchAllStores();
  }, [selectedInventory]);

  const fetchAllStores = async () => {
    setLoading(true);
    try {
      const res = await getStoreListApi();
      setStoreList(
        res?.data?.map((store) => ({
          store: {
            _id: store._id,
            name: store.name,
            location: store?.location?.name || "Unknown Location",
          },
          available_quantity: null,
        })) || []
      );
    } catch (error) {
      console.error("Error fetching all stores:", error);
      message.error("Failed to fetch stores");
    } finally {
      setLoading(false);
    }
  };

  const fetchStoreList = async () => {
    setLoading(true);
    try {
      const res = await getStoreByInventoryApi(selectedInventory);
      setStoreList(
        res?.data?.map((store) => ({
          store: {
            _id: store.store?._id,
            name: store.store?.name,
            location: store.store?.location?.name || "Unknown Location",
          },
          available_quantity: store?.available_quantity || 0,
        })) || []
      );
    } catch (error) {
      console.error("Error fetching store list:", error);
      message.error("Failed to fetch store list");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <Row justify="center">
        <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
          <Select
            allowClear
            onClear={() => {
              setSelectedInventory(null);
              setStoreList([]);
              setOptions([]);
            }}
            size="large"
            style={{ width: "40%" }}
            showSearch
            placeholder="Search inventory"
            value={selectedInventory}
            onSearch={setSearchValue}
            filterOption={false}
            options={options}
            onChange={setSelectedInventory}
            suffixIcon={<SearchOutlined />}
            notFoundContent={searchValue ? "No inventory found" : ""}
          />
        </Col>
      </Row>

      <Row justify="start" gutter={[16, 24]} style={{ marginTop: 50 }}>
        {!loading && storeList.length === 0 && selectedInventory && (
          <Col span={24} style={{ minHeight: 200, display: "flex", justifyContent: "center", marginTop: 20 }}>
            <Result icon={Empty.PRESENTED_IMAGE_SIMPLE} title={<Typography>This inventory is not found in any store</Typography>} />
          </Col>
        )}

        {storeList.map(({ store, available_quantity }) => (
          <Col key={store._id} span={6} style={{ display: "flex", justifyContent: "center" }}>
            <Card style={cardStyle}>
              <Form layout="vertical">
                <Typography.Title level={5} style={{ margin: 0 ,fontWeight: 500}}>
                  {store.name}
                </Typography.Title>
              </Form>

              <div style={infoStyle}>
                <Typography.Text>Location:</Typography.Text>
                <Typography.Text>{store.location}</Typography.Text>
              </div>

              {available_quantity !== null && (
                <div style={infoStyle}>
                  <Typography.Text>Available quantity:</Typography.Text>
                  <Tag color="purple">{available_quantity}</Tag>
                </div>
              )}
            </Card>
          </Col>
        ))}
      </Row>
    </Card>
  );
};

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  margin: 10,
  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
};

const infoStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: 10,
};

export default StoresList;
