import { Modal } from "antd";
import React, { useState } from "react";

const CreateWorkOrderModal = ({woModal,setWoModal}) => {

  const showModal = () => {
    setWoModal(true);
  };

  const handleOk = () => {
    setWoModal(false);
  };

  const handleCancel = () => {
    setWoModal(false);
  };
  return (
    <Modal
      title="Create Work Order"
      open={woModal}
      onOk={handleOk}
      onCancel={handleCancel}
    ></Modal>
  );
};

export default CreateWorkOrderModal;
