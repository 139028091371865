import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Select,
  Space,
  Input,
  Typography,
  Flex,
  Upload,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../../context/UserProvider";
import {
  getInventoryList,
  getStoreListApi,
} from "../../../../services/inventory.services";

import inputStyles from "../../../../assets/styles/Custom/Input.module.css";
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";

const GeneralDetails = ({ form, edit, setRefresh, requestDetails }) => {
  const params = useParams();
  const { userDetails } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [allStores, setAllStores] = useState([]);
  const [allInventories, setAllInventories] = useState([]);
  const [createIR, setCreateIR] = useState(false);

  useEffect(() => {
    fetchAllStores();
    fetchAllInventories();
  }, []);

  const fetchAllStores = async () => {
    try {
      const res = await getStoreListApi();
      setAllStores(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllInventories = async () => {
    try {
      const res = await getInventoryList();
      setAllInventories(res?.data?.inventoryItems);
    } catch (error) {
      console.log(error);
    }
  };

  const props = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Card
      title="Request Details"
      styles={{
        header: { backgroundColor: "#fff" },
      }}
    >
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Flex justify="space-between" style={{ width: "100%" }} gap={12}>
            <Form.Item
              style={{ width: "50%" }}
              label="Title"
              name="name"
              rules={[
                { required: true, message: "Please enter title" },
                {
                  whitespace: true,
                  message: "Title cannot be empty or contain only spaces",
                },
              ]}
            >
              <Input
                disabled={!edit}
                className={inputStyles.smInput}
                style={{ width: "100%" }}
                placeholder="Enter title"
              />
            </Form.Item>
            <Form.Item
              style={{ width: "50%" }}
              label="Store"
              name={"store"}
              rules={[{ required: true, message: "Please select store" }]}
            >
              <Select
                disabled={!edit}
                style={{ width: "100%" }}
                placeholder="Select store"
                options={allStores?.map((item) => ({
                  label: item?.name,
                  value: item?._id,
                }))}
              />
            </Form.Item>
          </Flex>
          <Form.Item name="status" hidden>
            <Input />
          </Form.Item>

          <Row gutter={[16, 6]} style={{ marginBottom: 6, marginTop: 10 }}>
            <Col span={7}>
              <Typography.Text style={{ fontSize: 13 }}>
                Inventory
              </Typography.Text>
            </Col>
            <Col span={6}>
              <Typography.Text style={{ fontSize: 13 }}>
                Description
              </Typography.Text>
            </Col>
            <Col span={5}>
              <Typography.Text style={{ fontSize: 13 }}>
                Requested quantity
              </Typography.Text>
            </Col>

            {params?.id && !edit && (
              <Col span={5}>
                <Typography.Text style={{ fontSize: 13 }}>
                  Approved quantity
                </Typography.Text>
              </Col>
            )}
          </Row>
          <Form.List
            name="inventory_list"
            initialValue={[{ type: "existing" }]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => {
                  const type = form.getFieldValue([
                    "inventory_list",
                    name,
                    "type",
                  ]);

                  return (
                    <Row key={key} gutter={[16, 6]} align="baseline">
                      <Form.Item {...restField} name={[name, "type"]} hidden>
                        <Input />
                      </Form.Item>
                      <Col span={7}>
                        <Form.Item
                          {...restField}
                          // label={index === 0 ? "Inventory" : ""}
                          name={[name, "inventory"]}
                          style={{ flex: 2 }}
                          rules={[
                            {
                              validator(_, value) {
                                if (type === "new" && !value) {
                                  return Promise.reject(
                                    new Error("Please enter inventory name")
                                  );
                                }
                                if (type === "existing" && !value) {
                                  return Promise.reject(
                                    new Error("Please select an inventory")
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          {type === "new" ? (
                            <Input
                              disabled={!edit}
                              placeholder="Enter inventory name"
                              
                              
                            />
                          ) : (
                            <Select
                              disabled={!edit}
                              allowClear
                              showSearch
                              virtual
                              placeholder="Select inventory"
                              options={allInventories?.map((item) => ({
                                label: item?.name,
                                value: item?._id,
                              }))}
                                onChange={(_, options) => {
                                  form.setFieldValue(
                                    ["inventory_list", name, "inventory_name"],
                                    options?.label
                                  );
                                }}
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Form.Item name={[name,"inventory_name"]}></Form.Item>
                      <Col span={6}>
                        <Form.Item
                          {...restField}
                          name={[name, "description"]}
                          // label={index === 0 ? "Description" : ""}
                          style={{ flex: 2 }}
                        >
                          <Input disabled={!edit} placeholder="Description" />
                        </Form.Item>
                      </Col>

                      <Col span={5}>
                        <Form.Item
                          {...restField}
                          name={[name, "requested_quantity"]}
                          // label={index === 0 ? "Requested Quantity" : ""}
                          style={{ flex: 1 }}
                          rules={[
                            {
                              validator(_, value) {
                                if (!value) {
                                  return Promise.reject(
                                    new Error("Please enter a valid quantity")
                                  );
                                }
                                if (!/^[0-9]+$/.test(value)) {
                                  return Promise.reject(
                                    new Error("Only numbers are allowed")
                                  );
                                }
                                if (value === "0") {
                                  return Promise.reject(
                                    new Error("Value must be greater than zero")
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            disabled={!edit}
                            placeholder="Requested Quantity"
                          />
                        </Form.Item>
                      </Col>

                      {params?.id && !edit && (
                        <Col span={5}>
                          <Form.Item
                            {...restField}
                            // label={index === 0 ? "Approved Quantity" : ""}
                            name={[name, "approved_quantity"]}
                            style={{ flex: 1 }}
                            rules={[
                              {
                                required: true,
                                message: "Missing approved quantity",
                              },
                              {
                                pattern: /^[0-9]+$/,
                                message: "Only numbers are allowed",
                              },
                            ]}
                          >
                            <Input
                              disabled={requestDetails?.status !== "open"}
                              placeholder="Approved Quantity"
                            />
                          </Form.Item>
                        </Col>
                      )}

                      <Col span={1} style={{ paddingTop: 5 }}>
                        {fields.length > 1 && edit && (
                          <DeleteOutlined
                            style={{ fontSize: 15 }}
                            onClick={() => remove(name)}
                          />
                        )}
                      </Col>
                    </Row>
                  );
                })}

                {edit && (
                  <Flex gap={12}>
                    <Form.Item style={{ width: "50%" }}>
                      <Button
                        type="dashed"
                        onClick={() => add({ type: "existing" })}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add existing item
                      </Button>
                    </Form.Item>
                    <Form.Item style={{ width: "50%" }}>
                      <Button
                        type="dashed"
                        onClick={() => add({ type: "new" })}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add new item
                      </Button>
                    </Form.Item>
                  </Flex>
                )}
              </>
            )}
          </Form.List>

          {/* <Form.Item name="file" label="File">
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item> */}
        </Col>
      </Row>
    </Card>
  );
};

export default GeneralDetails;
