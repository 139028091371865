import React, { useContext, useEffect, useState } from "react";
import CustomLayout from "../../../Layout";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Flex,
  Form,
  message,
  Popconfirm,
  Row,
  Tag,
  Typography,
} from "antd";
import btnStyles from "../../../../assets/styles/Custom/Button.module.css";

import { UserContext } from "../../../../context/UserProvider";
import { DeleteOutlined, EditOutlined, LeftOutlined } from "@ant-design/icons";
import typoStyles from "../../../../assets/styles/Custom/Typography.module.css";
import GeneralDetails from "./generalDetails";
import Comments from "./Comments/index";
import IntendRequestStatus from "./intendRequestStatus";
import TaskInfo from "./TaskInfo";
import {
  getIndentRequestDetailsApi,
  deleteIndentRequestApi,
  addIndentRequestApi,
  updateIndentRequestApi,
} from "../../../../services/inventory.services";
import InventoryInsigts from "./inventoryInsigts";

const CreateIntendRequest = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { userDetails } = useContext(UserContext);
  const [edit, setEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [requestDetails, setRequestDetails] = useState({});
  const [currStatus, setCurrStatus] = useState("open");
  const [existingInventoryList, setExistingInventoryList] = useState([]);
  useEffect(() => {
    params?.id ? getRequestDetails() : setEdit(true);
  }, [refresh]);

  const getRequestDetails = async () => {
    try {
      const res = await getIndentRequestDetailsApi(params?.id);
      setRequestDetails(res?.data);
      form.setFieldsValue({
        ...res?.data,
        inventory_list: res?.data?.inventory_list?.map((item) => ({
          ...item,
          inventory_name: item?.inventory?.name,
          inventory: item?.inventory_text || item?.inventory?._id,
          type: item?.inventory?._id ? "existing" : "new",
          approved_quantity:
            item?.approved_quantity === 0 ? "" : item?.approved_quantity,
        })),
        store: res?.data?.store?._id,
      });
      setCurrStatus(res?.data?.status);
      setExistingInventoryList(
        res?.data?.inventory_list
          ?.map((inv) => {
            if (inv?.inventory?._id) {
              return {
                label: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography.Text>{inv?.inventory?.name}</Typography.Text>{" "}
                    <Tag color="blue"> {inv?.inventory?.code}</Tag>
                  </div>
                ),
                value: inv?.inventory?._id,
                code: inv?.inventory?.code,
              };
            } else {
              return null;
            }
          })
          ?.filter(Boolean)
      );
    } catch (error) {
      console.log(error);
      navigate("/indent-requests");
    } finally {
    }
  };
  const refreshCall = () => {
    getRequestDetails();
    setEdit(false);
  };

  const deleteIntendRequest = async () => {
    try {
      const res = await deleteIndentRequestApi(params?.id);
      res && navigate("/indent-requests");
      message.success(res?.data?.message);
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    }
  };

const onFinish = async (values) => {
  const inventory_names = new Set();
  const inventoryList = [];

  for (const item of values?.inventory_list || []) {
    const inventoryName =
      item?.inventory_name?.trim()?.toLowerCase() ||
      item?.inventory?.trim()?.toLowerCase();

    if (inventory_names.has(inventoryName)) {
      message.error("Duplicate inventory names are not allowed");
      return; 
    }

    inventory_names.add(inventoryName);

    const updatedItem = { ...item };
    const isNew = updatedItem?.type === "new";

    delete updatedItem.type;
    delete updatedItem._id;

    inventoryList.push(
      isNew
        ? {
            ...updatedItem,
            requested_quantity: updatedItem?.requested_quantity || 1,
            inventory_text: updatedItem.inventory,
            inventory: undefined,
            approved_quantity: updatedItem?.approved_quantity || 0,
            inventory_name: undefined,
          }
        : {
            ...updatedItem,
            requested_quantity: updatedItem?.requested_quantity || 1,
            approved_quantity: updatedItem?.approved_quantity || 0,
            inventory_name: undefined,
          }
    );
  }

  const payload = {
    name: values?.name,
    store: values?.store,
    status: values?.status,
    inventory_list: inventoryList,
  };

  try {
    if (params?.id) {
      const res = await updateIndentRequestApi(params?.id, payload);
      message.success(res?.data?.message);
      refreshCall();
    } else {
      const res = await addIndentRequestApi(payload);
      message.success(res?.data?.message);
      navigate("/indent-requests");
    }
  } catch (error) {
    message.error(error?.response?.data?.message);
  }
};


  return (
    <CustomLayout
      header={
        <>
          {!params?.id ? (
            <Typography className={typoStyles.h6}>
              <LeftOutlined
                style={{ marginRight: "6px" }}
                onClick={() => navigate("/indent-requests")}
              />
              {params?.id ? "Update Indent Request" : "Create Indent Request"}
            </Typography>
          ) : (
            <Flex justify="space-between" align="center">
              <Typography className={typoStyles.h6}>
                {" "}
                <LeftOutlined
                  style={{ marginRight: "6px" }}
                  onClick={() => {
                    navigate("/indent-requests");
                  }}
                />
                {`Request-${requestDetails?.code}` || "Update Request"}
              </Typography>
              {!edit && currStatus === "open" && (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "12px" }}
                >
                  <Popconfirm
                    icon={null}
                    description="Are you sure to delete this request?"
                    onConfirm={(e) => {
                      deleteIntendRequest();
                    }}
                  >
                    <Button className={btnStyles.mdBtn}>
                      <span className="hiddenBelowTab">Delete</span>
                      <DeleteOutlined style={{ margin: "0px" }} />
                    </Button>
                  </Popconfirm>

                  <Button
                    className={btnStyles.mdBtn}
                    onClick={() => setEdit(true)}
                    type="primary"
                  >
                    <span className="hiddenBelowTab">Edit</span>
                    <EditOutlined style={{ margin: "0px" }} />
                  </Button>
                </div>
              )}
            </Flex>
          )}
        </>
      }
      footer={
        <>
          {(!params?.id || (params?.id && edit)) && (
            <Flex justify="flex-end" gap={12}>
              <Button
                className={btnStyles.mdBtn}
                onClick={() => {
                  if (params?.id) {
                    refreshCall();
                    setEdit(false);
                  } else {
                    navigate("/indent-requests");
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className={btnStyles.mdBtn}
                type="primary"
                onClick={form.submit}
              >
                Save
              </Button>
            </Flex>
          )}
        </>
      }
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
      >
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={16}>
            <GeneralDetails
              form={form}
              edit={edit}
              setRefresh={setRefresh}
              requestDetails={requestDetails}
            />
            {params?.id && <Comments workOrderID={params?.id} />}
          </Col>

          {params?.id && (
            <>
              <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                <IntendRequestStatus
                  requestDetails={requestDetails}
                  form={form}
                  currStatus={currStatus}
                />
                {requestDetails?.status === "open" && (
                  <InventoryInsigts
                    existingInventoryList={existingInventoryList}
                  />
                )}
                <TaskInfo requestDetails={requestDetails} />
              </Col>
            </>
          )}
        </Row>
      </Form>
    </CustomLayout>
  );
};

export default CreateIntendRequest;
