import { SendOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, Row } from "antd";
import React, { useState } from "react";

const ChatInput = ({ sendMessage }) => {
  const [messageContent, setMessageContent] = useState("");

  const handleSend = () => {
    if (messageContent.trim() !== "") {
      sendMessage(messageContent);
      setMessageContent("");  
    }
  };

  return (
    <Row gutter={[16, 16]} style={{ width: "100%", height: "100%" }}>
      <Col span={22}>
        <Card
          className="chat-input"
          styles={{
            body: {
              width: "100%",
              padding: "10px",
              display: "flex",
              justifyContent: "space-between",
            },
          }}
        >
          <Input
            variant="borderless"
            value={messageContent}
            onChange={(e) => setMessageContent(e.target.value)}
            placeholder="Ask questions, or type ‘/’ for commands"
            onPressEnter={handleSend} 
          />
        </Card>
      </Col>
      <Col span={2}>
        <Button type="default" style={{ width: "100%", height: "100%" }} onClick={handleSend}>
          <SendOutlined style={{ fontSize: "20px", color: "#ABABAB" }} />
        </Button>
      </Col>
    </Row>
  );
};

export default ChatInput;
