import React, { useContext } from "react";
import boltLogo from "../../../assets/images/chatIcons/bolt-logo.png";
import { Avatar, Card, Image } from "antd";
import { getAvatarBackgroundColor } from "../../../helpers/utility";
import { UserContext } from "../../../context/UserProvider";

  // Reusable Message Component
  const MessageUI = ({ sender, time, message, isUser }) => {
    const date = new Date(time);

    const formattedDate = date.toLocaleString('en-US', {
      day: '2-digit',
      month: 'short',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  
  const { userDetails } = useContext(UserContext);
  const selectedColor = getAvatarBackgroundColor(userDetails?.first_name);

  return (
    <div>
      <div className="message-header" style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
        {isUser ? (
          <Avatar
            style={{
              cursor: "pointer",
              backgroundColor: userDetails?.profile_pic ? "transparent" : selectedColor,
            }}
            src={userDetails?.profile_pic}
            size={24}
          >
            {!userDetails?.profile_pic && (
              <span style={{ fontSize: "18px" }}>
                {userDetails?.first_name?.[0]?.toUpperCase()}
              </span>
            )}
          </Avatar>
        ) : (
          <Avatar style={{ cursor: "pointer",background:"#7369F4",padding:"3px" }} src={boltLogo}  size={24}  />
        )}
        <div style={{display:"flex" ,gap:"10px"}}>
        <div className="message-header-name" style={{ marginLeft: "0px", fontWeight: "bold" }}>
          {isUser ? "You" : "Bolt"}
        </div>
        <div className="message-header-time" style={{ marginLeft: "auto", color: "#888", marginTop:"3px" }}>
          {formattedDate}
        </div>
        </div>
        
      </div>

      <Card
        className="message-body"
        title={null}
        style={{
          backgroundColor: isUser ? "#ffff" : "#EFEDFC",
          borderRadius: "10px",
          marginRight:"10px",
          marginLeft:"10px",
          marginBottom:"10px",
          maxWidth:"80%",
          boxShadow:"2px 2px 6px rgba(0, 0, 0, 0.10)"
        }}
        styles={{body:{padding: "0px 20px"}  }}
      >
        <p>{message || "No response , please try again"}</p>
      </Card>
    </div>
  );
};

export const UserMessageUI = ({ time , message = "Hey Bolt!" }) => {
  return <MessageUI sender="You" time={time} message={message} isUser />;
};

export const BotMessageUI = ({ time, message = "Hey there! I'm Bolt, your friendly neighborhood chatbot. How can I help you today?" }) => {
  return <MessageUI sender="Bolt" time={time} message={message} isUser={false} />;
};
