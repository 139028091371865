import {
  CheckCircleOutlined,
  CloseOutlined,
  LogoutOutlined,
  PauseCircleOutlined,
  RetweetOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { Button, Card, Flex, message, Popconfirm, Tag, Typography } from "antd";
import React from "react";

const IntendRequestStatus = ({ requestDetails, form, currStatus }) => {
  const statusMap = {
    open: { approve: "Approve", reject: "Reject", next: "approved" },
    approved: { approve: "Dispatch", reject: "On Hold", next: "dispatched" },
    dispatched: { approve: "Receive", reject: "Reject", next: "received" },
    "on-hold": { approve: "Dispatch", reject: "Reject", next: "dispatched" },
  };

  const tagColors = {
    open: "processing",
    approved: "gold",
    received: "green",
    "on-hold": "volcano",
    rejected: "red",
    dispatched: "purple",
  };

  const handleStatusChange = () => {
    if (currStatus === "open") {
      const inventoryList = form.getFieldValue("inventory_list");
      if (inventoryList.length === 0) {
        message.error("Please add inventory");
        return;
      }

      // for (let item of inventoryList) {
        
      //   // if (item?.approved_quantity == 0) {
      //   //   message.error("Approved quantity cannot be zero");
      //   //   return;
      //   // }
      //   // if (item?.approved_quantity > item?.requested_quantity) {
      //   //   message.error(
      //   //     "Approved quantity cannot be greater than requested quantity"
      //   //   );
      //   //   return;
      //   // }
      // }
    }

    if (statusMap[currStatus]) {
      form.setFieldsValue({ status: statusMap[currStatus].next });
      form.submit();
    }
  };

  const handleRejectStatus = () => {
    const rejectStatus = currStatus === "approved" ? "on-hold" : "rejected";
    form.setFieldsValue({ status: rejectStatus });
    form.submit();
  };

  const isFinalStatus = ["received", "rejected"].includes(currStatus);

  return (
    <Card
      title={
        <Flex gap={12} align="center">
          <Typography.Text style={{ fontSize: 16 }}>Status</Typography.Text>
          <Tag
            color={tagColors[currStatus]}
            icon={
              currStatus === "open" ? (
                <UnlockOutlined />
              ) : currStatus === "approved" ? (
                <RetweetOutlined />
              ) : currStatus === "dispatched" ? (
                <LogoutOutlined />
              ) : currStatus === "received" ? (
                <CheckCircleOutlined />
              ) : currStatus === "on-hold" ? (
                <PauseCircleOutlined />
              ) : (
                <CloseOutlined />
              )
            }
            style={{ padding: "4px 8px" }}
          >
            {requestDetails?.status?.toUpperCase()}
          </Tag>
        </Flex>
      }
      style={{ width: "100%" }}
    >
      <Flex gap={12}>
        {!isFinalStatus && currStatus !== "dispatched" && (
          <Popconfirm
            title={`${statusMap[currStatus]?.reject}`}
            description={
              "Are you sure to " +
              statusMap[currStatus]?.reject +
              " this request?"
            }
            onConfirm={handleRejectStatus}
            onCancel={(e) => e.stopPropagation()}
            okText="Yes"
            cancelText="No"
          >
            <Button style={{ width: "50%" }} size="large" type="default">
              {statusMap[currStatus]?.reject}
            </Button>
          </Popconfirm>
        )}
        {!isFinalStatus && (
          <Popconfirm
            placement="topLeft"
            title={`${statusMap[currStatus]?.approve}`}
            description={
              "Are you sure to " +
              statusMap[currStatus]?.approve +
              " this request?"
            }
            onConfirm={handleStatusChange}
            onCancel={(e) => e.stopPropagation()}
            okText="Yes"
            cancelText="No"
          >
            <Button style={{ width: "50%" }} size="large" type="primary">
              {statusMap[currStatus]?.approve}
            </Button>
          </Popconfirm>
        )}

        {currStatus === "received" && (
          <Typography.Text style={{ width: "100%", textAlign: "center" }}>
            Inventory received
          </Typography.Text>
        )}
        {currStatus === "rejected" && (
          <Typography.Text style={{ width: "100%", textAlign: "center" }}>
            Inventory request rejected
          </Typography.Text>
        )}
      </Flex>
    </Card>
  );
};

export default IntendRequestStatus;
