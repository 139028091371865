import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import InventoryList from "./Inventory";
import CustomLayout from "../Layout";
import { Col, ConfigProvider, Row, Tabs, Typography } from "antd";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import CategoryList from "./stores/categoryList";
import { getStoreListApi } from "../../services/inventory.services";

const Inventory = () => {
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const [viewType, setViewType] = useState("items");
  const [stores, setStores] = useState([]);
  const [selectedTab, setSelectedTab] = useState("inventory-registry");

  useEffect(() => {
    fetchStores();
  }, [selectedTab]);
  const fetchStores = async () => {
    try {
      const resp = await getStoreListApi();
      setStores(resp?.data || []);
    } catch (error) {
      console.error("Error fetching stores:", error);
      setStores([]);
    }
  };
  useEffect(() => {
    if (searchParams.get("type") == "items" || !searchParams.get("type")) {
      setViewType("items");
    } else {
      setViewType("categories");
    }
  }, [searchParams]);

  const tabItems = [
    {
      key: "items",
      label: "Items",
    },
    {
      key: "categories",
      label: "Categories",
    },
  ];
  const tabsInventory = [
    {
      key: "inventory-registry",
      label: "Inventory Registry",
      children: <InventoryList key={"inventory-registry"} allStores={stores} selectedStore={selectedTab}  />,
    },
    ...stores.map((store) => ({
      key: store?._id,
      label: store?.name,
      children: <InventoryList key={store?._id} allStores={stores} selectedStore={selectedTab} />,
    })),
  ];
  return (
    <CustomLayout
      header={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 35,
          }}
        >
          <Typography className={typoStyles.h6} style={{ marginTop: 6 }}>
            Inventory
          </Typography>
          <div style={{ height: 36 }}>
            <ConfigProvider
              theme={{
                components: {
                  Tabs: {
                    colorBorderSecondary: "#d0d5dd",
                  },
                },
              }}
            >
              <Tabs
                className="schedulerTabs"
                activeKey={viewType}
                onChange={(e) => navigate(`/inventory?type=${e}`)}
                moreIcon={null}
              >
                {tabItems?.map((item) => (
                  <Tabs.TabPane key={item?.key} tab={item?.label} />
                ))}
              </Tabs>
            </ConfigProvider>
          </div>
        </div>
      }
    >
      <>
        {viewType == "categories" ? (
          <Row gutter={[24, 18]}>
            <Col span={24}>
              <CategoryList />
            </Col>
          </Row>
        ) : (
          <Col span={24}>
            <Tabs
              activeKey={selectedTab}
              onChange={(key) => setSelectedTab(key)}
              type="card"
              size="middle"
              style={{
                marginBottom: 32,
              }}
              tabBarStyle={{
                marginBottom: 0,
              }}
            >
              {tabsInventory.map((tab) => (
                <Tabs.TabPane key={tab.key} tab={tab.label} children={tab.children} />
              ))}
            </Tabs>
          </Col>
        )}
      </>
    </CustomLayout>
  );
};

export default Inventory;
