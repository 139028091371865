import { BarChartOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import CreateWorkOrderModal from "./createWorkOrderModal";

const ChatCards = ({ getInsights }) => {
  const [woModal, setWoModal] = useState(false);

  return (
    <Row gutter={[16, 16]} style={{ width: "100%", height: "100%" }}>
      <Col span={6} lg={6} xs={24}>
        <Button
          style={{
            color: "#6941C6",
            borderColor: "#E9D7FE",
            backgroundColor: "#F9F5FF",
          }}
          className="chart-card-btn"
        >
          <PlusOutlined /> Add My Knowledge
        </Button>
      </Col>
      <Col span={6} lg={6} xs={24}>
        <Button
          style={{
            color: "#C68A41",
            borderColor: "#FEE8D7",
            backgroundColor: "#FFFBF5",
          }}
          color=""
          className="chart-card-btn"
          type="dashed"
          onClick={() => setWoModal(true)}
        >
          <PlusOutlined /> Create Work order
        </Button>
        {woModal && (
          <CreateWorkOrderModal woModal={woModal} setWoModal={setWoModal} />
        )}
      </Col>
      <Col span={6} lg={6} xs={24}>
        <Button
          style={{
            color: "#C641AC",
            borderColor: "#FED7F0",
            backgroundColor: "#FEF5FF",
          }}
          className="chart-card-btn"
          type="dashed"
          onClick={getInsights}
        >
          <BarChartOutlined /> Show Insights
        </Button>
      </Col>
      <Col span={6} lg={6} xs={24}>
        <Button
          style={{
            color: "#C64141",
            borderColor: "#FED7D7",
            backgroundColor: "#FFF5F5",
          }}
          className="chart-card-btn"
          type="dashed"
        >
          <EyeOutlined /> Show My Todo’s
        </Button>
      </Col>
    </Row>
  );
};

export default ChatCards;
