import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React from "react";
import { convertUTCToLocalFormat } from "../../../helpers/utility";

const ReturnToForm = ({
  selectedAllocation,
  showDrawer,
  form2,
  onClose,
  onFinish,
}) => {
  return (
    <Drawer
      width={500}
      title="Returned from"
      onClose={onClose}
      open={showDrawer}
      footer={
        <Flex gap={10} justify="flex-end">
          <Button onClick={onClose}>Close</Button>
          <Button
            type="primary"
            form="issue-form"
            onClick={() => form2.submit()}
          >
            Submit
          </Button>
        </Flex>
      }
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Flex gap={10} vertical>
            <Typography.Text style={{ fontSize: 12 }}>Store</Typography.Text>
            <Typography.Text>{selectedAllocation?.store?.name}</Typography.Text>
          </Flex>
        </Col>
        <Col span={24}>
          <Flex gap={10} vertical>
            <Typography.Text style={{ fontSize: 12 }}>Task</Typography.Text>
            {selectedAllocation?.occurrence?.code ? (
              <Space direction="horizontal" size={10}>
                <Tag color="blue">{selectedAllocation?.occurrence?.code}</Tag>
                <Typography.Text>
                  {dayjs(
                    convertUTCToLocalFormat(
                      selectedAllocation?.occurrence?.occurrence_date
                    )
                  ).format("YYYY-MM-DD")}
                </Typography.Text>
              </Space>
            ) : (
              <Tag color="blue" style={{ width: "fit-content" }}>
                {selectedAllocation?.workorder?.code}
              </Tag>
            )}
          </Flex>
        </Col>
        <Col span={12}>
          <Flex gap={10} vertical>
            <Typography.Text style={{ fontSize: 12 }}>
              Issued To
            </Typography.Text>
            <Typography.Text>
              {selectedAllocation?.issued_to?.first_name}{" "}
              {selectedAllocation?.issued_to?.last_name || ""}
            </Typography.Text>
          </Flex>
        </Col>
        <Col span={12}>
          <Flex gap={10} vertical>
            {" "}
            <Typography.Text style={{ fontSize: 12 }}>
              Issued date
            </Typography.Text>
            <Typography.Text>
              {" "}
              {selectedAllocation?.issued_date
                ? dayjs(
                    convertUTCToLocalFormat(selectedAllocation?.issued_date)
                  ).format("DD MMM hh:mm A")
                : "-"}
            </Typography.Text>
          </Flex>
        </Col>

        <Col span={12}>
          <Flex gap={10} vertical>
            <Typography.Text style={{ fontSize: 12 }}>
              Spare part
            </Typography.Text>
            <Typography.Text>
              {selectedAllocation?.inventory?.name || "-"}
            </Typography.Text>
          </Flex>
        </Col>
        <Col span={12}>
          <Flex gap={10} vertical>
            <Typography.Text style={{ fontSize: 12 }}>
              Issued quantity
            </Typography.Text>
            <Typography.Text>
              {selectedAllocation?.issued_quantity}
            </Typography.Text>
          </Flex>
        </Col>

        {/* <Col span={12}>
          <Flex gap={10} vertical>
            <Typography.Text style={{ fontSize: 12 }}>
              Used quantity
            </Typography.Text>
            <Typography.Text>
              {selectedAllocation?.total_used_quantity || "-"}
            </Typography.Text>
          </Flex>
        </Col> */}
        <Col span={12}>
          <Flex gap={10} vertical>
            <Typography.Text style={{ fontSize: 12 }}>
              Balance quantity
            </Typography.Text>
            <Typography.Text>
              {selectedAllocation?.balance_quantity || "-"}
            </Typography.Text>
          </Flex>
        </Col>
      </Row>
      <Form
        form={form2}
        layout="vertical"
        onFinish={onFinish}
        style={{ marginTop: 20 }}
      >
        <Form.Item
          label="Returned date"
          name="returned_date"
          rules={[
            {
              validator: (_, value, callback) => {
                if (value && value !== "") {
                  callback();
                } else {
                  callback("Please select a date");
                }
              },
            },
          ]}
        >
          <DatePicker
            format="YYYY-MM-DD HH:mm"
            // onChange={() => form1.setFieldsValue({ issue_time: null })}
            placeholder="Select start date & time"
            size="medium"
            showTime={{
              defaultValue: dayjs(),
            }}
            allowClear={false}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item
          label="Returned quantity"
          name="returned_quantity"
          rules={[
            {
              validator: (_, value, callback) => {
                if (value && value !== "") {
                  if (/^[0-9]+$/.test(value)) {
                    if (Number(value) <= 0) {
                      callback("Please enter a valid quantity greater than 0");
                    } else if (
                      Number(value) > selectedAllocation?.balance_quantity
                    ) {
                      callback(
                        "Returned quantity cannot be greater than balance quantity"
                      );
                    } else {
                      callback();
                    }
                  } else {
                    callback("Please enter a valid number");
                  }
                } else {
                  callback("Please enter quantity");
                }
              },
            },
          ]}
        >
          <Input
            placeholder="Enter returned quantity"
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ReturnToForm;
