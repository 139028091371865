import { Card, Col, Row, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { getAssetSparePartHistory } from "../../../services/assets.services";
import dayjs from "dayjs";
import {
  convertUTCToLocalFormat,
  formatNumber,
  tableComponents,
} from "../../../helpers/utility";
import { useNavigate } from "react-router-dom";

const SparePartHistory = ({ assetDetails, source }) => {
  const navigate = useNavigate();
  const [sparePartData, setSparePartData] = useState(null);

  const showCount = 4;

  const fetchAssetSparePartHistory = async () => {
    let query = { page: 1 };
    try {
      const res = await getAssetSparePartHistory(assetDetails?._id, query);
      setSparePartData(res?.data);
    } catch (error) {
      setSparePartData(null);
    }
  };

  useEffect(() => {
    if (assetDetails?._id) {
      fetchAssetSparePartHistory();
    }
  }, [assetDetails]);

  const tableColumns = [
    {
      title: "Spare part code",
      dataIndex: "code",
      key: "code",
      render: (_, record) => (
        <Typography.Text>{record?.inventory?.code || " - "}</Typography.Text>
      ),
    },
    {
      title: "Task code",
      dataIndex: "task_code",
      key: "task_code",
      render: (_, record) => {
        let taskId = record?.workorder?._id || record?.occurrence?._id || "";
        let taskCode =
          record?.workorder?.code || record?.occurrence?.code || "";
        let taskType = taskCode?.includes("WO")
          ? "work-orders"
          : taskCode?.includes("INS")
          ? "inspections"
          : "maintenances";
        if (taskId) {
          return (
            <Typography
              style={{ color: "#1677ff", cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/${taskType}/${taskId}${
                    taskType === "work-orders" ? "" : "/job"
                  }`,
                  { state: { source: `/assets/${assetDetails?._id}` } }
                )
              }
            >
              {taskCode}
            </Typography>
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: "Spare part used date",
      dataIndex: "latestUsedDate",
      key: "latestUsedDate",
      render: (val, record) => (
        <>
          {val
            ? dayjs(convertUTCToLocalFormat(val)).format("DD MMM YYYY")
            : "-"}
        </>
      ),
    },
    {
      title: "Quantity used",
      dataIndex: "totalUsedQuantity",
      key: "totalUsedQuantity",
    },
    {
      title: "Total cost",
      dataIndex: "cost",
      key: "cost",
      render: (val, record) =>
        `₹ ${formatNumber(
          (Number(record?.inventory?.cost?.toFixed(2)) || 0) *
            (Number(record?.totalUsedQuantity) || 0)
        )}`,
    },
  ];

  return (
    <Card
      title="Spare Parts History"
      extra={
        sparePartData?.totalInventories > showCount ? (
          <Typography
            style={{
              color: "#1677ff",
              cursor: "pointer",
              fontSize: 15,
              textDecoration: "underline",
            }}
            onClick={() =>
              navigate(`/assets/${assetDetails?._id}/spare-part-history`, {
                state: { assetCode: assetDetails?.code, source: source },
              })
            }
          >
            View all
          </Typography>
        ) : null
      }
      styles={{
        header: { backgroundColor: "#fff" },
        body: { padding: "0px" },
      }}
    >
      <Row>
        <Col span={24}>
          <Table
            scroll={{ x: 500, y: 250 }}
            columns={tableColumns}
            dataSource={sparePartData?.inventories?.slice(0, showCount) || []}
            pagination={false}
            components={tableComponents}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default SparePartHistory;
