import React, { useEffect, useRef } from "react";
import { Typography } from "antd";
import { UserMessageUI, BotMessageUI } from "./chatComponents/messageUI";
import InsightCards from "./chatComponents/insightCards";
import { LeftOutlined } from "@ant-design/icons";

const ChatUI = ({ insightsData, messageType, setMessageType, messages }) => {
  const messagesEndRef = useRef(null);

  useEffect(() => {
    // Scroll to the latest message when messages are updated
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  if (messageType === "") {
    return (
      <div
        style={{
          height: "50vh",
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "22px",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <Typography.Title level={2} style={{ color: "#989FAD" }}>
          What's on your mind? Let me help!
        </Typography.Title>
      </div>
    );
  }

  return (
    <>
      {messageType === "text" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "22px",
            height: "68vh",
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          {messages.map((msg, index) =>
            msg.sender === "Bolt" ? (
              <BotMessageUI key={index} message={msg.content} time={msg.timestamp} />
            ) : (
              <UserMessageUI key={index} message={msg.content} time={msg.timestamp} />
            )
          )}
          {/* Empty div to force scroll to the bottom */}
          <div ref={messagesEndRef} />
        </div>
      ) : messageType === "insights" ? (
        <div
          style={{
            width: "100%",
            height: "65vh",
            padding: "0px 5px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <div style={{ marginBottom: "10px", maxWidth: "70px", cursor: "pointer" }} onClick={() => setMessageType("")}>
            <LeftOutlined /> Back
          </div>
          <InsightCards insightsData={insightsData} />
        </div>
      ) : null}
    </>
  );
};

export default ChatUI;
