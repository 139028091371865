import React, { useState } from "react";
import CustomLayout from "../../Layout";
import { Col, Flex, Row, Tabs, Typography } from "antd";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import StoresList from "./storesList";

const AllStores = () => {
  return (
    <CustomLayout
      header={
        <Flex justify="space-between">
          <Typography className={typoStyles.h6}>
            Inventory availability in stores
          </Typography>
        </Flex>
      }
    >
      <Row>
        <Col span={24}>
          <StoresList />
        </Col>
      </Row>
    </CustomLayout>
  );
};

export default AllStores;
