import { Card, Col, Row, Steps, Typography } from "antd";
import dayjs from "dayjs";
import React from "react";
import { convertUTCToLocalFormat } from "../../../../helpers/utility";
import {
  CheckCircleOutlined,
  CloseOutlined,
  LogoutOutlined,
  PauseCircleOutlined,
  RetweetOutlined,
  UnlockOutlined,
} from "@ant-design/icons";

const TaskInfo = ({ requestDetails }) => {
  const displayDateTime = (dateStr) => {
    return (
      <>
        <span>
          {dayjs(convertUTCToLocalFormat(dateStr)).format("DD MMM YYYY")}
        </span>
        <span style={{ marginLeft: 10 }}>
          {dayjs(convertUTCToLocalFormat(dateStr)).format("hh:mm A")}
        </span>
      </>
    );
  };

  const requestedSteps = requestDetails?.logs?.map((item) => {
    return {
      title: item?.status.charAt(0).toUpperCase() + item?.status.slice(1),
      description: `${dayjs(
        convertUTCToLocalFormat(item?.action_taken_at)
      ).format("DD MMM YYYY")},  ${
        (item?.action_taken_by?.first_name || "") +
        " " +
        (item?.action_taken_by?.last_name || "")
      }`,
      icon:
        item?.status === "open" ? (
          <UnlockOutlined style={{ color: "#0BA5EC" }} />
        ) : item?.status === "approved" ? (
          <RetweetOutlined style={{ color: "#B57908" }} />
        ) : item?.status === "dispatched" ? (
          <LogoutOutlined style={{ color: "#6941C6" }} />
        ) : item?.status === "received" ? (
          <CheckCircleOutlined style={{ color: "green" }} />
        ) : item?.status === "on-hold" ? (
          <PauseCircleOutlined style={{ color: "#EF6820" }} />
        ) : (
          <CloseOutlined style={{ color: "#B54708" }} />
        ),
    };
  });

  return (
    <>
      {/* <Card styles={{ body: { padding: "16px" } }} style={{ marginTop: 12 }}>
        <Row gutter={[12, 4]}>
          <Col span={12}>Requested by</Col>
          <Col span={12}>
            {requestDetails?.creator?.first_name ||
            requestDetails?.creator?.last_name
              ? `${requestDetails?.creator?.first_name} ${
                  requestDetails?.creator?.last_name || ""
                }`
              : "-"}
          </Col>
          <Col span={12}>Approved by</Col>
          <Col span={12}>
            {requestDetails?.approved_by?.first_name ||
            requestDetails?.approved_by?.last_name
              ? `${requestDetails?.approved_by?.first_name} ${
                  requestDetails?.approved_by?.last_name || ""
                }`
              : "-"}
          </Col>
        </Row>
      </Card> */}
      <Card
        title="Logs"
        styles={{ body: { padding: "16px" } }}
        style={{ marginTop: 12 }}
      >
        <Row gutter={[12, 4]} style={{ paddingLeft: 20, paddingTop: 10 }}>
          {/* <Col span={12}>Initiated at</Col>
          <Col span={12}>
            {requestDetails?.created_at
              ? displayDateTime(requestDetails?.created_at)
              : "-"}
          </Col>
          <Col span={12}>Approved at</Col>
          <Col span={12}>
            {requestDetails?.approved_at ? displayDateTime(requestDetails?.approved_at) : "-"}
          </Col>
          <Col
            span={12}
            style={{ display: "flex", alignItems: "center", gap: 10 }}
          >
            <Typography>Dispatched at</Typography>
          </Col>
          <Col span={12}>
            {requestDetails?.dispatched_at
              ? displayDateTime(requestDetails?.dispatched_at)
              : "-"}
          </Col>
          <Col span={12}>Received at</Col>
          <Col span={12}>
            {requestDetails?.received_at ? displayDateTime(requestDetails?.received_at) : "-"}
          </Col> */}

          <Steps
            size="small"
            // progressDot
            direction="vertical"
            current={requestedSteps?.length - 1}
            items={requestedSteps}
          />
        </Row>
      </Card>
    </>
  );
};

export default TaskInfo;
