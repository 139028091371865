import React, { useEffect, useRef, useState } from "react";
import CustomLayout from "../../Layout";
import {
  Button,
  Col,
  Empty,
  Flex,
  Input,
  Result,
  Row,
  Skeleton,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import dayjs from "dayjs";
import {
  convertUTCToLocalFormat,
  debounce,
  getSearchDefaultValue,
  scrollToTopTable,
  tableComponents,
} from "../../../helpers/utility";
import {
  CheckCircleOutlined,
  CloseOutlined,
  LogoutOutlined,
  PauseCircleOutlined,
  RetweetOutlined,
  SearchOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getIndentRequestListApi } from "../../../services/inventory.services.js";
import "../inventory.css";
const IntendRequest = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const handleSearch = (e) => {
    setSearch(e?.target?.value);
  };



  const fetchAllIntendRequest = async () => {
    setLoading(true);
    try {
      let query = {};
      if (search) {
        query.name = search;
      }
      const res = await getIndentRequestListApi(query);
      setTableData(res?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchAllIntendRequest();
      scrollToTopTable(tableRef);
    };
    fetchData();
  }, [pageNumber, refresh]);

  const tagColors = {
    open: "processing",
    approved: "success",
    received: "gold",
    "on-hold": "volcano",
    rejected: "red",
    dispatched: "purple",
  };
  const tableColumns = [
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (val) => <Tag color="blue">{val}</Tag>,
    },
    {
      title: "Store",
      dataIndex: "store",
      key: "store",
      render: (val) => (val?.name ? val?.name : "-"),
    },
    {
      title: "Requested By",
      dataIndex: "creator",
      key: "creator",
      render: (val) =>
        val ? `${val?.first_name || ""} ${val?.last_name || ""}` : "-",
    },
    {
      title: "Requested at",
      dataIndex: "created_at",
      key: "created_at",
      render: (val) =>
        val ? dayjs(convertUTCToLocalFormat(val)).format("DD MMM YYYY") : "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (val) => (
        <Tag
          icon={
            val === "open" ? (
              <UnlockOutlined />
            ) : val === "approved" ? (
              <RetweetOutlined />
            ) : val === "dispatched" ? (
              <LogoutOutlined />
            ) : val === "received" ? (
              <CheckCircleOutlined />
            ) : val === "on-hold" ? (
              <PauseCircleOutlined />
            ) : (
              <CloseOutlined />
            )
          }
          style={{ padding: "4px 8px", minWidth: "100px" }}
          color={tagColors[val?.toLowerCase()] || "default"}
        >
          {val.charAt(0).toUpperCase() + val.slice(1)}
        </Tag>
      ),
    },
  ];
  return (
    <CustomLayout
      header={
        <Flex justify="space-between">
          <Typography
            className={typoStyles.h6}
          >{`Indent Requests (${tableData?.totalIndentRequests}) `}</Typography>
        </Flex>
      }
    >
      <Row>
        <Col span={24}>
          <Table
            size="large"
            ref={tableRef}
            title={() => (
              <Space
                align="center"
                style={{
                  width: "100%",
                  justifyContent: "end",
                  gap: 16,
                }}
              >
                <Input.Search
                  size="medium"
                  defaultValue={getSearchDefaultValue()}
                  onChange={debounce(handleSearch, 500)}
                  placeholder={`Search by request title or code`}
                  prefix={
                    <SearchOutlined
                      style={{ fontSize: 16, color: "#667085" }}
                    />
                  }
                  style={{ width: "300px" }}
                  className="custom-input-search"
                />
                <Button
                  type="primary"
                  size="medium"
                  onClick={() => {
                    navigate("/indent-requests/create");
                  }}
                >
                  {" "}
                  Raise an Indent
                </Button>
              </Space>
            )}
            columns={tableColumns}
            dataSource={tableData?.indentRequests}
            pagination={false}
            rowClassName
            scroll={{
              x: 1200,
              y: "55vh",
            }}
            loading={
              loading
                ? {
                    spinning: false,
                    indicator: null,
                  }
                : false
            }
            locale={{
              emptyText: loading ? (
                <Skeleton active />
              ) : (
                <Result
                  icon={Empty.PRESENTED_IMAGE_SIMPLE}
                  title={<Typography>No request's found</Typography>}
                />
              ),
            }}
            bordered
            components={tableComponents}
            onRow={(item) => {
              return {
                onClick: (e) => {
                  navigate(`/indent-requests/${item?._id}`, {});
                },
                style: { cursor: "pointer" },
              };
            }}
          />
        </Col>
      </Row>
    </CustomLayout>
  );
};

export default IntendRequest;
