import { Button, Col, ConfigProvider, Flex, Image, Menu, Row } from "antd";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import logo from "../../assets/images/cmms-logos/CMMSLogo.svg";
import bolt from "../../assets/images/cmms-logos/bolt.png";
import "../Layout/Layout.css";

import knowledgeHubActive from "../../assets/images/sidemenuIcons/KnowledgeHub.svg";
import historyIcon from "../../assets/images/chatIcons/history 1.svg";
import exploreIcon from "../../assets/images/chatIcons/Explore.svg";
import "../Layout/Layout.css";
import { UserContext } from "../../context/UserProvider";

import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PlusOutlined,
  SettingOutlined,
  VerticalLeftOutlined,
  VerticalRightOutlined,
  WindowsOutlined,
} from "@ant-design/icons";
import ChooseOrgModal from "../Organisations/ChooseOrgModal";
import { getItem, removeItem } from "../../helpers/localStorage";

const ChatSideMenu = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const { userDetails } = useContext(UserContext);
  const [openKeys, setOpenKeys] = useState(["history"]);

  const finalLogo =
    userDetails?.activeOrg?.organization?.profile_pic &&
    userDetails.activeOrg.organization.profile_pic.trim() !== ""
      ? userDetails.activeOrg.organization.profile_pic
      : logo;

  const handleOnOpen = () => {
    setOpenModal(true);
  };

  function getMenuItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }
  function getImage(path, activeimage, inactiveImage) {
    return (
      <Image
        preview={false}
        src={
          path === location.pathname.split("/")[1] ? activeimage : inactiveImage
        }
        id={path}
        alt={path}
        style={{
          height: collapsed ? "22px" : "18px",
          width: "auto",
          verticalAlign: "text-bottom",
          marginRight: "18px",
        }}
      />
    );
  }
  const items = [
    getMenuItem(
      "History",
      "history",
      getImage("history", historyIcon, historyIcon),
      []
      // [
      //   getMenuItem("Today", ""),
      //   getMenuItem("Yesterday", "chat"),
      //   getMenuItem("Last Week", "chat"),
      // ]
    ),
    getMenuItem(
      "My Knowledge",
      "chat",
      getImage("dashboard", knowledgeHubActive, knowledgeHubActive)
    ),
    ,
  ];

  return (
    <Row style={{ height: "100%" }} align={"top"} gutter={[0, 24]}>
      <ChooseOrgModal
        openModal={openModal}
        onOpenChange={handleOnOpen}
        closable={true}
        onCancel={() => setOpenModal(false)}
      />
      <Col span={24}>
        <ConfigProvider
          theme={{
            theme: {
              fontFamily: "Montserrat",
            },
            components: {
              Menu: {
                itemBg: "#2f3542",
                itemColor: "#2f3542",
                itemSelectedColor: "#7369F4",
                itemSelectedBg: "#f0f1f6",
                itemHoverBg: "#fff",
                itemHoverColor: "#2f3542",
              },
            },
          }}
        >
          <Flex
            align="center"
            justify={collapsed ? "center" : "space-between"}
            style={{
              marginBottom: "24px",
              minHeight: "50px",
              gap: "8px",
              paddingLeft: 12,
              paddingRight: 12,
            }}
          >
            <Image
              preview={false}
              className={
                collapsed
                  ? "sideMenuLogoWithCollapse"
                  : "sideMenuLogoWithNoCollapse"
              }
              src={collapsed ? bolt : finalLogo ? finalLogo : logo}
              alt="Bolt Logo"
              style={{ marginLeft: "20px", height: "50px", width: "auto" }}
            />
            {!collapsed ? (
              <VerticalRightOutlined
                style={{ fontSize: "20px", color: "#2f3542" }}
                onClick={() => setCollapsed((prev) => !prev)}
              />
            ) : (
              <VerticalLeftOutlined
                style={{ fontSize: "20px", color: "#2f3542" }}
                onClick={() => setCollapsed((prev) => !prev)}
              />
            )}
          </Flex>
          <Button
            style={{
              display: "flex",
              gap: "8px",
              justifyContent: "center",
              width: collapsed ? "50%" : "80%",
              height: "auto",
              margin: collapsed ? "5% 25%" : "5% 10%",
              padding: "8px 0px",
            }}
            type="primary"
          >
            <Image width={"18px"}  src={exploreIcon} preview={false} />
            {!collapsed && "Explore Bolt"}
          </Button>
          <Menu
            className={collapsed ? "sideMenuCollapsed" : "sideMenu"}
            style={{ border: "0px", maxHeight: "75vh", overflowY: "auto" }}
            selectedKeys={[location.pathname.split("/")[1]]}
            mode="inline"
            openKeys={openKeys}
            onOpenChange={(keys) => setOpenKeys(keys)}
            items={items}
            onClick={(e) => {
              let locStorageData = getItem("applied_filters", true);
              if (!location?.pathname?.includes(e.key) && locStorageData) {
                removeItem("applied_filters");
              }
              if (e.key == "org") {
                navigate(`/org/users`);
              } else {
                navigate(`/${e.key}`);
              }
            }}
          />
        </ConfigProvider>
      </Col>
      <Col span={24} style={{ placeSelf: "end" }}>
        <Button
          style={{
            width: collapsed ? "50%" : "80%",
            height: "auto",
            margin: collapsed ? "5% 25%" : "5% 10%",
            padding: "8px 0px",
          }}
          type="primary"
          onClick={() => navigate("/dashboard")}
        >
          <ArrowLeftOutlined style={{ fontSize: "16px", fontWeight: 600 }} />
          {!collapsed && "Back to Dashboard"}
        </Button>
      </Col>
    </Row>
  );
};

export default ChatSideMenu;
