import React, { useContext, useEffect, useState } from "react";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import CustomLayout from "../../Layout";
import {
  Form,
  Col,
  Row,
  Typography,
  Flex,
  Button,
  message,
  Popconfirm,
  Tag,
  Space,
  Modal,
  DatePicker,
} from "antd";
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import GeneralDetails from "./GeneralDetails";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import DigitalSignature from "./DigitalSignature";
import {
  createWorkOrderApi,
  deleteWorkOrderApi,
  getWorkorderByIDApi,
  updateWorkOrderApi,
} from "../../../services/work-orders.services";
import dayjs from "dayjs";
import Comments from "./Comments";
import WorkOrderStatus from "./WorkOrderStatus";
import {
  ONPREMISE,
  deleteWO,
  editWO,
  verifyPM,
} from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserProvider";
import {
  convertToUTCFormat,
  convertUTCToLocalFormat,
  deleteCond,
  editCond,
  renameFile,
} from "../../../helpers/utility";
import SOPDetails from "./SOPDetails";
import { getProductionLines } from "../../../services/assets.services";
import SparePartSection from "../../Blocks/SparePartSection";
import TaskInfo from "../../Blocks/TaskInfo";
import SparePartList from "../../Blocks/SpareParts/SparePartList";

const CreateWorkOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const params = useParams();
  const [woDocuments, setWoDocuments] = useState([]);
  const [edit, setEdit] = useState(false);
  const [workOrderID, setWorkOrderID] = useState(null);
  const [workOrderDetails, setWorkOrderDetails] = useState({});
  const { userDetails } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [sopData, setSopData] = useState({});
  const [answers, setAnswers] = useState({});
  const [productionLines, setProductionLines] = useState([]);
  const [plId, setPlId] = useState(null);
  const [showDateModal, setShowDateModal] = useState(false);
  const [currWoStatus, setCurrWoStatus] = useState("");
  const [selectedWoDate, setSelectedWoDate] = useState(dayjs());
  const {
    source,
    assetCode,
    mro_id,
    description,
    scheduled_start_date,
    mro_type,
    asset_id,
    disableType,
  } = location.state || {};

  const onFinish = async (values) => {
    let selectedAsset = form.getFieldValue("asset");
    let selectedProductionLine = form.getFieldValue("production_line");
    if (!selectedAsset && !selectedProductionLine) {
      message.error("Please enter either asset or production line");
    } else {
      const storageType =
        userDetails?.activeOrg?.organization?.settings?.storage;
      const formatFiles = (docs) =>
        storageType == ONPREMISE
          ? docs
          : docs?.map((each) => (each ? renameFile(each) : ""));

      delete values?.asset_image;
      delete values?.asset_status;
      delete values?.comments;
      delete values?.assigned_list;

      let formatedData = {
        ...values,
        start_date: values?.start_date
          ? convertToUTCFormat(values.start_date)
          : "",
        files: values?.files?.length > 0 && formatFiles(values?.files),
      };

      if (Object.keys(formatedData).includes("verifiers")) {
        if (params?.id && formatedData?.verifiers?.length === 0) {
          formatedData = { ...formatedData, verifiers: [] };
        }
      }

      if (Object.keys(formatedData).includes("end_date")) {
        formatedData = {
          ...formatedData,
          end_date: values?.end_date ? convertToUTCFormat(values.end_date) : "",
        };
      }

      !params?.id && !formatedData?.end_date && delete formatedData?.end_date;
      !formatedData?.start_date && delete formatedData?.start_date;
      !formatedData?.files?.length > 0 && delete formatedData?.files;
      if (params?.id) {
        formatedData = {
          ...formatedData,
          issueCode: formatedData?.issueCode || "",
          typeObj: formatedData?.typeObj || "",
        };
      }
      !params?.id &&
        formatedData?.verifiers?.length === 0 &&
        delete formatedData?.verifiers;

      if (!params?.id && mro_id) {
        formatedData = { ...formatedData, mro: mro_id };
      }

      try {
        const resp = params?.id
          ? await updateWorkOrderApi(params?.id, formatedData)
          : await createWorkOrderApi(formatedData);
        if (params?.id) {
          message.success(resp?.data?.message);
          getWorkOrder();
          setEdit(false);
        } else {
          message.success(resp?.data?.message);
          setWorkOrderID(resp?.data?._id);
        }
      } catch (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!!"
        );
      }
    }
  };

  const mapWorkOrderData = (data) => {
    return {
      ...data,
      asset: data?.asset?._id,
      production_line: data?.production_line?._id,
      asset_image: data?.asset?.file,
      asset_status: data?.asset?.status,
      verifiers: data?.verifiers?.map((i) => i?._id),
      sop: data?.sop?._id,
      start_date: data?.start_date
        ? dayjs(convertUTCToLocalFormat(data?.start_date))
        : null,
      end_date: data?.end_date
        ? dayjs(convertUTCToLocalFormat(data?.end_date))
        : null,
      assigned_groups: data?.assigned_groups?.map((each) => each?._id),
      assigned_users: data?.assigned_users?.map((each) => each?._id),
      assigned_list: [
        ...(data?.assigned_groups?.map((each) => each?._id) || []),
        ...(data?.assigned_users?.map((each) => each?._id) || []),
      ],
    };
  };

  const getWorkOrder = async () => {
    try {
      const resp = await getWorkorderByIDApi(params?.id);
      const data = mapWorkOrderData(resp?.data?.workorder);
      setWoDocuments(resp?.data?.workorder?.files || []);
      delete data?.comments;
      setWorkOrderDetails(data);
      setPlId(data?.production_line);
      form.setFieldsValue({
        ...data,
        typeObj: data?.typeObj?._id,
        issueCode: data?.issueCode?._id,
      });

      // form.setFieldsValue({...data,typeObj:{label:data?.typeObj?.name,value:data?.typeObj?._id}});
      setSopData(resp?.data?.workorder?.sop || {});
      setAnswers(resp?.data?.sopSubmission?.responses || {});
    } catch (error) {
      navigate("/work-orders");
    }
  };

  const deleteWorkOrder = async () => {
    try {
      const resp = await deleteWorkOrderApi(params?.id);
      resp && navigate("/work-orders");
      message.success(resp?.data?.message);
    } catch (error) {
      message.error(error?.repsonse?.data?.message);
    }
  };

  const fetchProductionLines = async () => {
    try {
      const res = await getProductionLines();
      setProductionLines(
        res?.data?.assets?.map((item) => ({
          ...item,
          label: item?.name,
          value: item?._id,
        }))
      );
    } catch (error) {}
  };

  useEffect(() => {
    params?.id && setWorkOrderID(params?.id);
    params?.id ? getWorkOrder() : setEdit(true);
    !params?.id && form.setFieldValue("start_date", dayjs());
  }, [params]);

  useEffect(() => {
    fetchProductionLines();
    if (!params?.id && mro_id) {
      form.setFieldsValue({
        name: description ? description : "",
        start_date: scheduled_start_date
          ? dayjs(convertUTCToLocalFormat(scheduled_start_date))
          : null,
        asset: asset_id ? asset_id : undefined,
        typeObj: mro_type ? mro_type : undefined,
        // typeObj: mro_type ? {
        //   label: mro_type?.name,
        //   value: mro_type?._id
        // } : undefined,
      });
    }
  }, [workOrderDetails]);

  const refreshCall = () => {
    getWorkOrder();
    setEdit(false);
  };

  const onEditClick = (status) => {
    setCurrWoStatus(status);
    if (status === "closed") {
      setSelectedWoDate(
        workOrderDetails?.ended_at
          ? dayjs(convertUTCToLocalFormat(workOrderDetails?.ended_at))
          : dayjs()
      );
    } else {
      setSelectedWoDate(
        workOrderDetails?.started_at
          ? dayjs(convertUTCToLocalFormat(workOrderDetails?.started_at))
          : dayjs()
      );
    }
    setShowDateModal(true);
  };

  const onUpdateWoDate = async (dateVal = null) => {
    let payload = { status: currWoStatus };
    if (currWoStatus === "started" && dateVal) {
      payload = {
        ...payload,
        status: "closed",
        started_at: convertToUTCFormat(dateVal),
      };
    } else if (currWoStatus === "closed" && dateVal) {
      payload = { ...payload, ended_at: convertToUTCFormat(dateVal) };
    }

    try {
      const resp = await updateWorkOrderApi(params?.id, payload);
      if (resp?.data) {
        message.success(resp?.data?.message);
        refreshCall();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong!!");
    }
  };

  const handleDisabledDate = (current) => {
    if (currWoStatus === "closed" && workOrderDetails?.started_at) {
      const startDate = dayjs(
        convertUTCToLocalFormat(workOrderDetails?.started_at)
      );
      return (
        current &&
        (current.isBefore(startDate, "day") || current > dayjs().endOf("day"))
      );
    } else if (
      currWoStatus === "started" &&
      workOrderDetails?.ended_at &&
      workOrderDetails?.start_date
    ) {
      const scheduledStartDate = workOrderDetails?.start_date;
      const endDate = dayjs(
        convertUTCToLocalFormat(workOrderDetails?.ended_at)
      );
      return (
        current &&
        (current.isBefore(scheduledStartDate, "day") ||
          current.isAfter(endDate, "day"))
      );
    } else {
      return false;
    }
  };
  const handleDisabledTime = (current) => {
    if (currWoStatus === "closed" && workOrderDetails?.started_at) {
      const startDate = dayjs(
        convertUTCToLocalFormat(workOrderDetails?.started_at)
      );
      if (!startDate) {
        return {};
      }
      let endDate1 = dayjs();
      const startHours = startDate.hour();
      const startMinutes = startDate.minute();
      const endHours1 = endDate1.hour();
      const endMinutes1 = endDate1.minute();

      if (
        current &&
        current.isSame(startDate, "day") &&
        current.isSame(endDate1, "day")
      ) {
        return {
          disabledHours: () =>
            Array.from({ length: 24 }, (_, i) => i).filter(
              (hour) => hour < startHours || hour > endHours1
            ),
          disabledMinutes: (selectedHour) => {
            if (selectedHour === startHours && startHours == endHours1) {
              return Array.from({ length: 60 }, (_, i) => i).filter(
                (min) => min < startMinutes || min > endMinutes1
              );
            }
            if (selectedHour === startHours) {
              return Array.from({ length: 60 }, (_, i) => i).filter(
                (min) => min < startMinutes
              );
            }
            if (selectedHour === endHours1) {
              return Array.from({ length: 60 }, (_, i) => i).filter(
                (min) => min > endMinutes1
              );
            }
            return [];
          },
        };
      }
      if (current && current.isSame(startDate, "day")) {
        return {
          disabledHours: () => Array.from({ length: startHours }, (_, i) => i),
          disabledMinutes: (selectedHour) => {
            if (selectedHour === startHours) {
              return Array.from({ length: startMinutes }, (_, i) => i);
            }
            return [];
          },
        };
      }
      if (current && current.isSame(endDate1, "day")) {
        return {
          disabledHours: () =>
            Array.from({ length: 24 }, (_, i) => i).splice(endHours1 + 1),
          disabledMinutes: (selectedHour) => {
            if (selectedHour === endHours1) {
              return Array.from({ length: 60 }, (_, i) => i).splice(
                endMinutes1 + 1
              );
            }
            return [];
          },
        };
      }
      return {};
    } else if (
      currWoStatus === "started" &&
      workOrderDetails?.ended_at &&
      workOrderDetails?.start_date
    ) {
      const scheduledStartDate = workOrderDetails?.start_date;
      const endDate = dayjs(
        convertUTCToLocalFormat(workOrderDetails?.ended_at)
      );

      const scheduledStartHours = scheduledStartDate.hour();
      const scheduledStartMinutes = scheduledStartDate.minute();
      const endHours = endDate.hour();
      const endMinutes = endDate.minute();

      if (
        current &&
        current.isSame(scheduledStartDate, "day") &&
        current.isSame(endDate, "day")
      ) {
        return {
          disabledHours: () =>
            Array.from({ length: 24 }, (_, i) => i).filter(
              (hour) => hour < scheduledStartHours || hour > endHours
            ),
          disabledMinutes: (selectedHour) => {
            if (
              selectedHour === scheduledStartHours &&
              scheduledStartHours == endHours
            ) {
              return Array.from({ length: 60 }, (_, i) => i).filter(
                (min) => min < scheduledStartMinutes || min > endMinutes
              );
            }
            if (selectedHour === scheduledStartHours) {
              return Array.from({ length: 60 }, (_, i) => i).filter(
                (min) => min < scheduledStartMinutes
              );
            }
            if (selectedHour === endHours) {
              return Array.from({ length: 60 }, (_, i) => i).filter(
                (min) => min > endMinutes
              );
            }
            return [];
          },
        };
      }
      if (current && current.isSame(scheduledStartDate, "day")) {
        return {
          disabledHours: () =>
            Array.from({ length: scheduledStartHours }, (_, i) => i),
          disabledMinutes: (selectedHour) => {
            if (selectedHour === scheduledStartHours) {
              return Array.from({ length: scheduledStartMinutes }, (_, i) => i);
            }
            return [];
          },
        };
      }
      if (current && current.isSame(endDate, "day")) {
        return {
          disabledHours: () =>
            Array.from({ length: 24 }, (_, i) => i).splice(endHours + 1),
          disabledMinutes: (selectedHour) => {
            if (selectedHour === endHours) {
              return Array.from({ length: 60 }, (_, i) => i).splice(
                endMinutes + 1
              );
            }
            return [];
          },
        };
      }
      return {};
    } else {
      return {};
    }
  };

  return (
    <CustomLayout
      header={
        !params?.id ? (
          <Typography className={typoStyles.h6}>
            <LeftOutlined
              style={{ marginRight: "6px" }}
              onClick={() => navigate("/work-orders")}
            />
            {params?.id ? "Update Work Order" : "Create Work Order"}
          </Typography>
        ) : (
          <Flex justify="space-between">
            <Typography
              className={typoStyles.h6}
              style={{
                gap: "6px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <LeftOutlined
                style={{ marginRight: "6px" }}
                onClick={() => {
                  if (source) {
                    navigate(source, { state: { assetCode: assetCode } });
                  } else {
                    navigate("/work-orders");
                  }
                }}
              />
              {workOrderDetails?.code}
              {workOrderDetails?.code?.startsWith("WOS") && (
                <Tag className={tagStyles.mdTag}>SYSTEM GENERATED</Tag>
              )}
              {workOrderDetails?.mark_as_verified && (
                <Tag className={tagStyles.mdRndTag} color="green">
                  Verified
                </Tag>
              )}
            </Typography>
            <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
              {
                // deleteCond(
                //   deleteWO,
                //   workOrderDetails?.creator?._id,
                //   userDetails?.activeOrg?.role,
                //   userDetails?._id
                // )
                (userDetails?.activeOrg?.role === "admin" ||
                  (userDetails?.activeOrg?.role === "manager" &&
                    workOrderDetails?.creator?.role !== "admin")) && (
                  <Popconfirm
                    icon={null}
                    description="Are you sure to delete this work order?"
                    onConfirm={(e) => {
                      deleteWorkOrder();
                    }}
                  >
                    <Button className={btnStyles.mdBtn}>
                      <span className="hiddenBelowTab">Delete</span>
                      <DeleteOutlined style={{ margin: "0px" }} />
                    </Button>
                  </Popconfirm>
                )
              }
              {!edit &&
                !workOrderDetails?.mark_as_verified &&
                editCond(
                  editWO,
                  workOrderDetails?.creator?._id,
                  userDetails?.activeOrg?.role,
                  userDetails?._id
                ) && (
                  <Button
                    className={btnStyles.mdBtn}
                    onClick={() => setEdit(true)}
                    type="primary"
                  >
                    <span className="hiddenBelowTab">Edit</span>

                    <EditOutlined style={{ margin: "0px" }} />
                  </Button>
                )}
            </div>
          </Flex>
        )
      }
      footer={
        <>
          {(!params?.id || (params?.id && edit)) && (
            <Flex justify="flex-end" gap={12}>
              <Button
                className={btnStyles.mdBtn}
                onClick={() => {
                  if (params?.id) {
                    setEdit(false);
                    getWorkOrder();
                  } else {
                    form.resetFields();
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className={btnStyles.mdBtn}
                type="primary"
                onClick={form.submit}
                loading={loading}
              >
                Save
              </Button>
            </Flex>
          )}
        </>
      }
    >
      <>
        <Form
          layout="vertical"
          requiredMark={false}
          form={form}
          onFinish={onFinish}
        >
          <Row gutter={[24, 24]}>
            <Col xs={24} md={24} lg={24} xl={16}>
              <GeneralDetails
                edit={edit}
                form={form}
                workOrderDetails={workOrderDetails}
                woDocuments={woDocuments}
                setWoDocuments={setWoDocuments}
                setSopData={setSopData}
                productionLines={productionLines}
                setPlId={setPlId}
                disableType={
                  (!params?.id && disableType) ||
                  (params?.id &&
                    workOrderDetails?.mro?.need_verification === "verified")
                }
                mro_id={mro_id}
                scheduled_start_date={scheduled_start_date}
              />
            </Col>
            {params?.id && (
              <Col xs={24} md={24} lg={24} xl={8}>
                <WorkOrderStatus
                  workOrderDetails={workOrderDetails}
                  refreshCall={refreshCall}
                />
                <TaskInfo
                  details={workOrderDetails}
                  typeValue="WO"
                  showEdit={
                    workOrderDetails?.status == "closed" &&
                    !workOrderDetails?.mark_as_verified &&
                    ((workOrderDetails?.verifiers?.length > 0 &&
                      workOrderDetails?.verifiers?.includes(
                        userDetails?._id
                      )) ||
                      (!workOrderDetails?.verifiers?.length &&
                        verifyPM.includes(userDetails?.activeOrg?.role)))
                  }
                  onEditClick={onEditClick}
                />
                {userDetails?.activeOrg?.organization?.settings
                  ?.qc_verification &&
                  workOrderDetails?.closed_by?._id && (
                    <DigitalSignature
                      workOrderDetails={workOrderDetails}
                      fetchWODetails={getWorkOrder}
                    />
                  )}
              </Col>
            )}
            <Col xs={24} md={24} lg={24} xl={16}>
              <SOPDetails
                edit={edit}
                workOrderDetails={workOrderDetails}
                sopData={sopData}
                setSopData={setSopData}
                answers={answers}
                setAnswers={setAnswers}
                form={form}
              />
            </Col>
            {/* {params?.id &&
              (userDetails?.activeOrg?.organization?.settings
                ?.inventory_module ||
                userDetails?.activeOrg?.organization?.settings
                  ?.track_spare_parts) && (
                <Col xs={24} md={24} lg={24} xl={16}>
                  <SparePartSection
                    form={form}
                    edit={edit}
                    productionLines={productionLines}
                    plId={plId}
                    inventoryRequestData={workOrderDetails?.inventory_request}
                    orderId={workOrderDetails?._id}
                    type={"WO"}
                    refreshCall={refreshCall}
                    verifyStatus={
                      workOrderDetails?.mark_as_verified ||
                      userDetails?.activeOrg?.role === "operator"
                    }
                    taskStatus={workOrderDetails?.status}
                  />
                </Col>
              )} */}
            {params?.id && (
              <Col xs={24} md={24} lg={24} xl={16}>
                <SparePartList
                  productionLines={productionLines}
                  plId={plId}
                  assetId={workOrderDetails?.asset}
                  orderId={workOrderDetails?._id}
                  type={"WO"}
                />
              </Col>
            )}
            <Col xs={24} md={24} lg={24} xl={16}>
              <Comments
                form={form}
                workOrderID={workOrderID}
                setWorkOrderID={setWorkOrderID}
                edit={edit}
                verifyStatus={workOrderDetails?.mark_as_verified}
              />
            </Col>
            {workOrderDetails?.status == "closed" &&
              !workOrderDetails?.mark_as_verified &&
              (userDetails?.activeOrg?.role === "admin" ||
                (workOrderDetails?.verifiers?.length > 0 &&
                  workOrderDetails?.verifiers?.includes(userDetails?._id)) ||
                (!workOrderDetails?.verifiers?.length &&
                  verifyPM.includes(userDetails?.activeOrg?.role))) && (
                <Col
                  xs={24}
                  md={24}
                  lg={24}
                  xl={16}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    size="medium"
                    style={{
                      width: "40vw",
                      margin: "auto",
                    }}
                    type={"primary"}
                    onClick={() => onFinish({ mark_as_verified: true })}
                  >
                    Mark as Verified <CheckOutlined />
                  </Button>
                </Col>
              )}
          </Row>
        </Form>
        {showDateModal && (
          <Modal
            title=""
            open={showDateModal}
            onCancel={() => setShowDateModal(false)}
            footer={null}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              <Typography>{`WO ${
                currWoStatus === "closed" ? "closed" : "started"
              } at`}</Typography>
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                value={selectedWoDate}
                onChange={(date) => setSelectedWoDate(date)}
                placeholder="Select date"
                size="medium"
                allowClear={false}
                showTime={{
                  defaultValue: dayjs("00:00", "HH:mm"),
                }}
                style={{
                  width: "70%",
                }}
                disabledDate={handleDisabledDate}
                disabledTime={handleDisabledTime}
                showNow={false}
              />
              <Button
                type="primary"
                className={btnStyles.smBtn}
                style={{ float: "right", marginTop: 10 }}
                onClick={() => {
                  setShowDateModal(false);
                  onUpdateWoDate(selectedWoDate);
                }}
              >
                Submit
              </Button>
            </Space>
          </Modal>
        )}
      </>
    </CustomLayout>
  );
};

export default CreateWorkOrder;
