import { Button, Col, Form, Input, Modal, Radio, Row, Select, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import { getInventoryList } from "../../../services/inventory.services";
import { debounce } from "lodash";

const AddSparePartUsage = ({
  showAddDrawer,
  setShowAddDrawer,
  plId,
  selectedPlAssets,
  assetId,
  storesList,
  issuedStoresList,
  invAllocationMap,
  onAddEditAllocation,
}) => {
  const [sparePartType, setSparePartType] = useState("high_value");
  const [lowValueInventoryList, setLowValueInventoryList] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [currQuantity, setCurrQuantity] = useState(0);
  const [issuedInventoryList, setIssuedInventoryList] = useState([]);

  const [addSpareForm] = Form.useForm();

  const fetchLowValueInventoryList = useCallback(
    debounce(async (searchVal) => {
      let storeId = addSpareForm.getFieldValue("store");
      if (!searchVal || !storeId) return;
      try {
        let query = { store: storeId, name: searchVal, high_value: false };
        const res = await getInventoryList(query);
        let inventories = res?.data?.inventoryItems?.map((s) => ({
          label: s?.name,
          value: s?._id,
          balance_quantity: s?.available_quantity,
        }));
        setLowValueInventoryList(inventories);
      } catch (error) {
        setLowValueInventoryList([]);
      }
    }, 500),
    []
  );

  useEffect(() => {
    fetchLowValueInventoryList(searchVal);
  }, [searchVal, fetchLowValueInventoryList]);

  const handleSparePartChange = (balance_quantity = 0) => {
    let prevUsedQ = getExistingUsedQuantity();
    setCurrQuantity(balance_quantity);
    addSpareForm.setFieldValue("used_quantity", prevUsedQ || "");
  };

  const getExistingUsedQuantity = () => {
    let asset_id = plId ? addSpareForm.getFieldValue("spare_asset") : assetId;
    let store_id = addSpareForm.getFieldValue("store");
    let inventory_id = addSpareForm.getFieldValue("spare_part");

    return invAllocationMap?.[inventory_id]?.[store_id]?.[asset_id] || 0;
  };

  return (
    <Modal
      title="Attach spare part"
      width={600}
      open={showAddDrawer}
      onCancel={() => setShowAddDrawer(false)}
      maskClosable={false}
      footer={null}
    >
      <Form
        requiredMark={false}
        form={addSpareForm}
        layout="vertical"
        onFinish={onAddEditAllocation}
        style={{ width: "100%", marginTop: 16 }}
      >
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Radio.Group
              style={{ marginBottom: 15 }}
              value={sparePartType}
              onChange={(e) => {
                setSparePartType(e?.target?.value);
                setCurrQuantity(0);
                setLowValueInventoryList([]);
                setIssuedInventoryList([]);
                addSpareForm.resetFields();
              }}
            >
              <Radio value={"high_value"}>High Value</Radio>
              <Radio value={"low_value"}>Low Value</Radio>
            </Radio.Group>
          </Col>
          {plId && (
            <Col span={12}>
              <Form.Item
                label="Asset"
                name="spare_asset"
                rules={[
                  {
                    required: true,
                    message: "Please select asset",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Select
                  placeholder="Select asset"
                  style={{ width: "100%" }}
                  className={inputStyles.smSelect}
                  showSearch={true}
                  optionFilterProp="label"
                  options={selectedPlAssets}
                />
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Form.Item
              label="Store"
              name="store"
              rules={[
                {
                  required: true,
                  message: "Please select store",
                },
              ]}
              style={{ width: "100%" }}
            >
              <Select
                placeholder="Select store"
                style={{ width: "100%" }}
                className={inputStyles.smSelect}
                showSearch={true}
                optionFilterProp="label"
                onChange={(value, option) => {
                  sparePartType === "high_value" &&
                    setIssuedInventoryList(value ? option?.inventories : []);
                  addSpareForm.resetFields(["spare_part", "used_quantity"]);
                }}
                options={
                  sparePartType === "high_value" ? issuedStoresList : storesList
                }
              />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              label="Spare part"
              name="spare_part"
              rules={[
                {
                  required: true,
                  message: "Please select spare part",
                },
              ]}
              style={{ width: "100%" }}
            >
              {sparePartType === "high_value" ? (
                <Select
                  placeholder="Select spare part"
                  style={{ width: "100%" }}
                  className={inputStyles.smSelect}
                  showSearch={true}
                  optionFilterProp="label"
                  onChange={(value, option) =>
                    handleSparePartChange(option?.balance_quantity)
                  }
                  options={issuedInventoryList}
                />
              ) : (
                <Select
                  placeholder="Select spare part"
                  style={{ width: "100%" }}
                  className={inputStyles.smSelect}
                  showSearch={true}
                  onSearch={(value) => setSearchVal(value)}
                  filterOption={false}
                  onChange={(value, option) =>
                    handleSparePartChange(option?.balance_quantity)
                  }
                  options={lowValueInventoryList}
                  notFoundContent={searchVal ? "No inventory found" : ""}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Used quantity"
              name="used_quantity"
              style={{ width: "100%" }}
              rules={[
                {
                  validator: (_, value, callback) => {
                    if (value && value !== "") {
                      if (/^[0-9]+$/.test(value)) {
                        if (Number(value) <= 0) {
                          callback(
                            "Please enter a valid quantity greater than 0"
                          );
                        } else if (
                          Number(value) >
                          currQuantity + getExistingUsedQuantity()
                        ) {
                          callback(
                            "Used quantity cannot be more than balance quantity"
                          );
                        } else {
                          callback();
                        }
                      } else {
                        callback("Please enter a valid number");
                      }
                    } else {
                      callback("Please enter quantity");
                    }
                  },
                },
              ]}
            >
              <Input
                style={{ width: "100%" }}
                className={inputStyles.smInput}
                placeholder="Enter quantity"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Tag className={tagStyles.smRndTag} color="geekblue">
              {`Balance Quantity: ${currQuantity + getExistingUsedQuantity()}`}
            </Tag>
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              className={btnStyles.smBtn}
              style={{ float: "right", marginTop: 12 }}
              onClick={() => addSpareForm.submit()}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddSparePartUsage;
