import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Flex,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {
  getInventoryList,
  getStoreListApi,
  getWOPMListApi,
} from "../../../services/inventory.services";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { convertUTCToLocalFormat, debounce } from "../../../helpers/utility";
import dayjs from "dayjs";

const IssuedToForm = ({ form1, onFinish, onClose, showDrawer, users }) => {
  const [allStores, setAllStores] = useState([]);
  const [searchWoPm, setSearchWoPm] = useState("");
  const [searchStore, setSearchStore] = useState("");
  const [allPMWO, setAllPMWO] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [allInventories, setAllInventories] = useState([]);
  const [searchInventory, setSearchInventory] = useState("");

  useEffect(() => {
    fetchAllStores();
  }, [searchStore]);

  useEffect(() => {
    if (searchWoPm) {
      fetchPMWO();
    }
  }, [searchWoPm]);

  useEffect(() => {
    if (searchInventory && selectedStore) {
      fetchAllInventories({
        name: searchInventory,
        store: selectedStore,
        high_value: true,
      });
    }
  }, [searchInventory]);

  const fetchAllInventories = useCallback(
    debounce(async (query) => {
      if (!query) return;
      try {
        const res = await getInventoryList(query);
        setAllInventories(
          res?.data?.inventoryItems.map((item) => ({
            label: item?.name,
            value: item?._id,
            available_quantity: item?.available_quantity,
          })) || []
        );
      } catch (e) {
        console.error(e);
        message.error("Failed to fetch search results");
      }
    }, 500),
    []
  );

  const fetchAllStores = async () => {
    try {
      const res = await getStoreListApi();
      setAllStores(
        res?.data?.map((store) => ({ label: store.name, value: store._id }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPMWO = async () => {
    try {
      const res = await getWOPMListApi({ name: searchWoPm });
      const PMWO = [
        ...(res?.data?.workorders ?? []).map((wo) => ({ ...wo, type: "wo" })),
        ...(res?.data?.occurrences ?? []).map((pm) => ({ ...pm, type: "pm" })),
      ];

      setAllPMWO(
        PMWO.map((wo) => ({
          label: (
            <div>
              {wo.type === "wo" ? (
                <Tag color="blue">{wo.code}</Tag>
              ) : (
                <div style={{ padding: "0px 0px", margin: 0, display: "flex",justifyContent:"space-between" }}>
                  <Tag color="green">{wo.code}</Tag>
                  <Typography.Text>
                    {dayjs(convertUTCToLocalFormat(wo.occurrence_date)).format(
                      "MMM D"
                    )}
                  </Typography.Text>
                </div>
              )}
            </div>
          ),
          value: `${wo.type}-${wo._id}`,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Drawer
      width={600}
      title="Issued to"
      onClose={onClose}
      open={showDrawer}
      footer={
        <Flex gap={10} justify="flex-end">
          <Button onClick={onClose}>Close</Button>
          <Button
            type="primary"
            form="issue-form"
            onClick={() => form1.submit()}
          >
            Submit
          </Button>
        </Flex>
      }
    >
      <Form form={form1} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="store"
          label="Store"
          rules={[
            {
              required: true,
              message: "Please select store!",
            },
          ]}
        >
          <Select
            showSearch
            onSelect={(value) => setSelectedStore(value)}
            allowClear
            onClear={() => setSelectedStore("")}
            options={allStores}
            placeholder="Select store"
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          name="task_id"
          label="WO / PM"
          rules={[
            {
              required: true,
              message: "Please select WO / PM!",
            },
          ]}
        >
          <Select
            showSearch
            onSearch={(value) => setSearchWoPm(value)}
            placeholder="Select WO / PM"
            filterOption={false}
            allowClear
            options={allPMWO}
          />
        </Form.Item>
        <Form.Item
          name="issued_to"
          label="Issued To"
          rules={[{ required: true, message: "Please select user!" }]}
        >
          <Select
            placeholder="Select user"
            options={users}
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          name="issued_date"
          label="Issue Date"
          rules={[{ required: true, message: "Please select issue date!" }]}
        >
          <DatePicker
            format="YYYY-MM-DD HH:mm"
            // onChange={() => form1.setFieldsValue({ issue_time: null })}
            placeholder="Select start date & time"
            size="medium"
            showTime={{
              defaultValue: dayjs(),
            }}
            allowClear={false}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>

        <Row gutter={16} style={{ marginBottom: 5 }}>
          <Col span={10}>
            <Typography.Text style={{ fontSize: 12 }}>
              Inventory
            </Typography.Text>
          </Col>
          <Col span={7} offset={5}>
            <Typography.Text style={{ fontSize: 12 }}>
              {" "}
              Issue quantity
            </Typography.Text>
          </Col>
        </Row>
        <Form.List
          name={"inventory_list"}
          label="Inventory"
          rules={[
            { required: true, message: "Please add at least one inventory!" },
          ]}
          initialValue={[{}]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row
                  key={field.key}
                  style={{ display: "flex", marginBottom: 8 }}
                  gutter={16}
                >
                  {/* Inventory Selection */}
                  <Col span={10}>
                    <Form.Item
                      name={[field.name, "inventory"]}
                      rules={[
                        { required: true, message: "Please select inventory!" },
                      ]}
                    >
                      <Select
                        // style={{ width: 200 }}
                        showSearch
                        onSearch={(value) => setSearchInventory(value)}
                        placeholder="Select inventory"
                        filterOption={false}
                        options={allInventories}
                        onChange={(value, option) => {
                          form1.setFieldsValue({
                            inventory_list: {
                              [field.name]: {
                                available_quantity: value
                                  ? option?.available_quantity || 0
                                  : 0,
                                issued_quantity: "",
                              },
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={5}>
                    <Form.Item name={[field.name, "available_quantity"]}>
                      {/* <Input value={<Tag>{ }</Tag>} disabled /> */}
                      <Tag
                        color="blue"
                        style={{
                          maxWidth: "110px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "inline-block",
                        }}
                      >
                        Available:{" "}
                        {form1.getFieldValue([
                          "inventory_list",
                          field.name,
                          "available_quantity",
                        ]) || 0}
                      </Tag>
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      name={[field.name, "issued_quantity"]}
                      // dependencies={[field.name, "inventory"]}
                      rules={[
                        {
                          validator: (_, value) => {
                            if (!value) {
                              return Promise.reject(
                                new Error("Please enter issue quantity!")
                              );
                            }
                            if (!/^[0-9]+$/.test(value)) {
                              return Promise.reject(
                                new Error("Only numbers are allowed")
                              );
                            }
                            if (value <= 0) {
                              return Promise.reject(
                                new Error("Value must be greater than zero")
                              );
                            }

                            const availableQuantity = form1.getFieldValue([
                              "inventory_list",
                              field.name,
                              "available_quantity",
                            ]);

                            if (
                              availableQuantity !== undefined &&
                              value > availableQuantity
                            ) {
                              return Promise.reject(
                                new Error(
                                  "Cannot issue more than available quantity"
                                )
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input style={{}} placeholder="Enter issue quantity" />
                    </Form.Item>
                  </Col>

                  {fields.length > 1 && (
                    <Col span={2} style={{ marginTop: 4 }}>
                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Col>
                  )}
                </Row>
              ))}

              <Form.Item>
                <Button
                  type="dashed"
                  size="small"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                  style={{ height: "44px" }}
                >
                  Add Inventory
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Drawer>
  );
};

export default IssuedToForm;
