import {
  Button,
  Card,
  Col,
  Form,
  Input,
  List,
  Modal,
  Row,
  Space,
  Tag,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import { formatNumber } from "../../../helpers/utility";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import AddSparePartUsage from "./AddSparePartUsage";
import {
  createAllocationApi,
  getAllocationListApi,
  getStoreListApi,
  updateAllocationApi,
} from "../../../services/inventory.services";

const SparePartList = ({ productionLines, plId, assetId, orderId, type }) => {
  const [allocationList, setAllocationList] = useState([]);
  const [invAllocationMap, setInvAllocationMap] = useState({});
  const [storesList, setStoresList] = useState([]);
  const [issuedStoresList, setIssuedStoresList] = useState([]);
  const [selectedPlAssets, setSelectedPlAssets] = useState([]);
  const [currEntry, setCurrEntry] = useState(null);
  const [showAddDrawer, setShowAddDrawer] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [editForm] = Form.useForm();

  const fetchStores = async () => {
    try {
      const res = await getStoreListApi();
      let stores = res?.data?.map((s) => ({
        label: s?.name,
        value: s?._id,
      }));
      setStoresList(stores);
    } catch (error) {
      setStoresList([]);
    }
  };

  const fetchAllocationList = async () => {
    let query = {};
    if (type === "WO") {
      query = { ...query, workorder: orderId };
    } else if (type === "PM") {
      query = { ...query, occurrence: orderId };
    }
    try {
      const resp = await getAllocationListApi(query);
      let allocations = [],
        allocationMap = {},
        issuedStores = [],
        storeIds = [];

      resp?.data?.allocations?.map((item) => {
        let balance_quantity = item?.is_allocation
          ? (item?.issued_quantity || 0) -
            (item?.total_used_quantity || 0) -
            (item?.returned_quantity || 0)
          : item?.available_quantity || 0;
        let allocationObj = {
          _id: item?._id,
          store: item?.store,
          inventory: item?.inventory,
        };

        if (allocationMap[item?.inventory?._id]) {
          if (!allocationMap[item?.inventory?._id][item?.store?._id]) {
            allocationMap[item.inventory._id] = {
              ...allocationMap[item.inventory._id],
              [`${item?.store?._id}`]: { alloc_id: item?._id },
            };
          }
        } else {
          allocationMap = {
            ...allocationMap,
            [`${item?.inventory?._id}`]: {
              [`${item?.store?._id}`]: { alloc_id: item?._id },
            },
          };
        }

        item?.assets_list?.map((s) => {
          allocations.push({
            ...allocationObj,
            asset: s?.asset,
            used_quantity: s?.used_quantity,
            balance_quantity: balance_quantity + s?.used_quantity,
          });

          if (
            allocationMap[item?.inventory?._id][item?.store?._id]["alloc_id"]
          ) {
            if (
              !allocationMap[item?.inventory?._id][item?.store?._id][
                s?.asset?._id
              ]
            ) {
              allocationMap[item.inventory._id][item.store._id] = {
                ...allocationMap[item.inventory._id][item.store._id],
                [`${s?.asset?._id}`]: s?.used_quantity,
              };
            }
          }
        });

        if (item?.is_allocation) {
          let inventoryObj = {
            label: item?.inventory?.name,
            value: item?.inventory?._id,
            balance_quantity: balance_quantity,
          };
          if (!storeIds.includes(item?.store?._id)) {
            storeIds.push(item?.store?._id);
            issuedStores.push({
              label: item?.store?.name,
              value: item?.store?._id,
              inventories: [inventoryObj],
            });
          } else {
            let storeIndex = issuedStores?.findIndex(
              (st) => st.value == item?.store?._id
            );
            if (storeIndex != -1) {
              issuedStores[storeIndex].inventories.push(inventoryObj);
            }
          }
        }
      });

      // console.log("ssee", allocations, allocationMap, issuedStores);
      setAllocationList(allocations);
      setInvAllocationMap(allocationMap);
      setIssuedStoresList(issuedStores);
    } catch (error) {
      setAllocationList([]);
      setInvAllocationMap({});
      setIssuedStoresList([]);
    }
  };

  useEffect(() => {
    if (orderId) {
      fetchAllocationList();
    }
  }, [orderId, refresh]);

  useEffect(() => {
    fetchStores();
  }, []);

  useEffect(() => {
    if (currEntry) {
      editForm.setFieldsValue({
        used_quantity: currEntry?.used_quantity,
        total_cost:
          Number(currEntry?.used_quantity || 0) *
          Number(currEntry?.inventory?.cost || 0),
      });
    }
  }, [currEntry]);

  useEffect(() => {
    if (plId) {
      let plObject = productionLines?.filter((i) => i?.value === plId)?.[0];
      setSelectedPlAssets(
        plObject?.assets?.map((p) => ({
          label: p?.asset?.name,
          value: p?.asset?._id,
        }))
      );
    } else {
      setSelectedPlAssets([]);
    }
  }, [plId, productionLines]);

  const refreshContent = () => {
    setRefresh((prev) => !prev);
  };

  const onAddEditAllocation = async (values) => {
    console.log("alloc-form-values", values);
    let query = { actionType: "use_inventory" };

    let store_id = currEntry?._id ? currEntry?.store?._id : values?.store;
    let inventory_id = currEntry?._id
      ? currEntry?.inventory?._id
      : values?.spare_part;
    let asset_id = plId
      ? currEntry?._id
        ? currEntry?.asset?._id
        : values?.spare_asset
      : assetId;
    let createPayload = {
      store: store_id,
      asset: asset_id,
      is_allocation: false,
      inventory_list: [
        {
          inventory: inventory_id,
          used_quantity: values?.used_quantity,
        },
      ],
    };
    if (type === "WO") {
      createPayload.workorder = orderId;
    } else {
      createPayload.occurrence = orderId;
    }

    let updatePayload = {
      asset: asset_id,
      used_quantity: values?.used_quantity,
    };
    let allocationId =
      invAllocationMap?.[inventory_id]?.[store_id]?.["alloc_id"];

    console.log(createPayload, updatePayload, allocationId, invAllocationMap);

    try {
      const res = allocationId
        ? await updateAllocationApi(allocationId, query, updatePayload)
        : await createAllocationApi(query, createPayload);

      if (res?.data) {
        message.success(res?.data?.message);
        refreshContent();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setShowAddDrawer(false);
      setCurrEntry(null);
    }
  };

  const handleQuantityChange = (value, cost) => {
    editForm.setFieldsValue({
      used_quantity: value,
      total_cost: (Number(value) || 0) * Number(cost),
    });
  };

  const renderListItem = (item) => {
    return (
      <List.Item>
        <Row gutter={[16, 0]} style={{ width: "100%" }}>
          {plId && <Col span={5}>{item?.asset?.name}</Col>}
          <Col span={5}>{item?.store?.name}</Col>
          <Col span={5}>
            <Space direction="vertical" style={{ gap: 2 }}>
              <Typography>{item?.inventory?.name}</Typography>
              {item?._id === currEntry?._id &&
                item?.asset?._id === currEntry?.asset?._id && (
                  <Tag className={tagStyles.smRndTag} color="geekblue">
                    {`Balance Quantity: ${item?.balance_quantity || 0}`}
                  </Tag>
                )}
            </Space>
          </Col>
          <Col span={3}>
            {item?._id === currEntry?._id &&
            item?.asset?._id === currEntry?.asset?._id ? (
              <Form.Item
                name="used_quantity"
                style={{ width: "100%" }}
                rules={[
                  {
                    validator: (_, value, callback) => {
                      if (value && value !== "") {
                        if (/^[0-9]+$/.test(value)) {
                          if (Number(value) > Number(item?.balance_quantity)) {
                            callback(
                              "Used quantity cannot be more than balance quantity"
                            );
                          } else {
                            callback();
                          }
                        } else {
                          callback("Please enter a valid number");
                        }
                      }
                    },
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  className={inputStyles.smInput}
                  placeholder="Enter quantity"
                  onChange={(e) =>
                    handleQuantityChange(
                      e?.target?.value,
                      currEntry?.inventory?.cost
                    )
                  }
                />
              </Form.Item>
            ) : (
              `${formatNumber(Number(item?.used_quantity) || 0)}`
            )}
          </Col>
          <Col span={3}>
            {item?._id === currEntry?._id &&
            item?.asset?._id === currEntry?.asset?._id ? (
              <Form.Item name="total_cost" style={{ width: "100%" }}>
                <Input
                  style={{ width: "100%" }}
                  className={inputStyles.smInput}
                  placeholder="Total cost"
                  disabled={true}
                />
              </Form.Item>
            ) : (
              `₹ ${formatNumber(
                Number(
                  (
                    Number(item?.used_quantity || 0) *
                    Number(item?.inventory?.cost || 0)
                  ).toFixed(2)
                )
              )}`
            )}
          </Col>
          <Col span={3}>
            {item?._id === currEntry?._id &&
            item?.asset?._id === currEntry?.asset?._id ? (
              <Space style={{ width: "100%", gap: 8 }}>
                <Button
                  type=""
                  // onClick={() => form1.submit()}
                  // loading={loading}
                  style={{ textAlign: "center" }}
                  icon={
                    <CheckOutlined
                      style={{
                        fontSize: 20,
                        color: "green",
                        cursor: "pointer",
                      }}
                    />
                  }
                  onClick={() => editForm.submit()}
                />
                <Button
                  type=""
                  // disabled={loading}
                  // style={{
                  //   pointerEvents: loading ? "none" : "auto",
                  //   opacity: loading ? 0.5 : 1,
                  // }}
                  onClick={() => setCurrEntry(null)}
                  icon={
                    <CloseOutlined
                      style={{ fontSize: 20, color: "red", cursor: "pointer" }}
                    />
                  }
                />
              </Space>
            ) : (
              <EditOutlined
                style={{ fontSize: 16, cursor: "pointer" }}
                onClick={() => setCurrEntry(item)}
              />
            )}
          </Col>
        </Row>
      </List.Item>
    );
  };

  return (
    <Card
      title="Spare Parts Used"
      styles={{ header: { backgroundColor: "#fff" } }}
    >
      <Form
        requiredMark={false}
        form={editForm}
        onFinish={onAddEditAllocation}
        style={{ width: "100%" }}
      >
        {allocationList && allocationList?.length > 0 && (
          <List
            bordered
            loading={false}
            dataSource={allocationList}
            header={
              <Row gutter={[16, 0]} style={{ width: "100%" }}>
                {plId && <Col span={5}>Asset</Col>}
                <Col span={5}>Store</Col>
                <Col span={5}>Spare part</Col>
                <Col span={3}>Used Quantity</Col>
                <Col span={3}>Total cost</Col>
              </Row>
            }
            renderItem={(item) => renderListItem(item)}
          />
        )}
      </Form>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        // disabled={verifyStatus}
        style={{ marginTop: 16 }}
        onClick={() => {
          setCurrEntry(null);
          setShowAddDrawer(true);
        }}
      >
        Attach spare part
      </Button>
      {showAddDrawer && (
        <AddSparePartUsage
          showAddDrawer={showAddDrawer}
          setShowAddDrawer={setShowAddDrawer}
          plId={plId}
          selectedPlAssets={selectedPlAssets}
          assetId={assetId}
          storesList={storesList}
          issuedStoresList={issuedStoresList}
          invAllocationMap={invAllocationMap}
          onAddEditAllocation={onAddEditAllocation}
        />
      )}
    </Card>
  );
};

export default SparePartList;
