import React, { useContext, useEffect, useState } from "react";
import CustomLayout from "../../Layout";
import {
  Form,
  Col,
  Row,
  Card,
  Flex,
  Button,
  message,
  Space,
  Typography,
  Modal,
  DatePicker,
} from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GeneralDetails from "./GeneralDetails";
import SOPDetails from "./SOPDetails";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import Comments from "./Comments";
import MaintananceStatus from "./MaintanaceStatus.js";
import { updateOccurenceApi } from "../../../services/maintenance.services.js";
import Recurrence from "./Recurrence.js";
import History from "./History.js";
import {
  convertToUTCFormat,
  convertUTCToLocalFormat,
} from "../../../helpers/utility";
import {
  generateInitialScheduleString,
  getIntialValues,
  getJob,
  getMaintanace,
  handleDynamicValidations,
  handleResponse,
  initializeParams,
  prepareValues,
  submitForm,
  updateScheduleString,
} from "./helper.js";
import {
  INSPECTION,
  MAINTANANCETEXT,
  verifyPM,
} from "../../../constants/defaultKeys.js";
import { UserContext } from "../../../context/UserProvider.js";
import Header from "./Header.js";
import { getProductionLines } from "../../../services/assets.services";
import SparePartSection from "../../Blocks/SparePartSection";
import TaskInfo from "../../Blocks/TaskInfo";
import dayjs from "dayjs";
import SparePartList from "../../Blocks/SpareParts/SparePartList";

const CreatePM = ({ type = MAINTANANCETEXT }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();
  const [woDocuments, setWoDocuments] = useState([]);
  const [edit, setEdit] = useState(false);
  const [workOrderID, setWorkOrderID] = useState(null);
  const [maintanaceDetails, setMaintanceDetails] = useState({});
  const [typeofRec, setTypeOfRec] = useState(1);
  const [occurString, setOccurString] = useState("");
  const { userDetails } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [sopData, setSopData] = useState({});
  const [answers, setAnswers] = useState({});
  const [occDate, setOccDate] = useState(null);
  const location = useLocation();
  const [isInspection, setIsInspection] = useState(false);
  const [productionLines, setProductionLines] = useState([]);
  const [plId, setPlId] = useState(null);
  const [showDateModal, setShowDateModal] = useState(false);
  const [currPMStatus, setCurrPMStatus] = useState("");
  const { source, assetCode } = location.state || {};
  const [selectedPmDate, setSelectedPmDate] = useState(dayjs());

  const fetchProductionLines = async () => {
    try {
      const res = await getProductionLines();
      setProductionLines(
        res?.data?.assets?.map((item) => ({
          ...item,
          label: item?.name,
          value: item?._id,
        }))
      );
    } catch (error) {}
  };

  const setFunctions = {
    setWorkOrderID,
    setMaintanceDetails,
    setSopData,
    setTypeOfRec,
    setWoDocuments,
    setAnswers,
    setOccurString,
    setEdit,
    setIsInspection,
    setPlId,
  };

  useEffect(() => {
    initializeParams(params, form, navigate, setFunctions, occDate);
  }, [params, occDate]);

  useEffect(() => {
    generateInitialScheduleString(setOccurString, typeofRec);
  }, []);

  useEffect(() => {
    updateScheduleString(form, setOccurString, typeofRec);
  }, [typeofRec]);

  useEffect(() => {
    fetchProductionLines();
  }, [maintanaceDetails]);

  const onFinish = async (values) => {
    let selectedAsset = form.getFieldValue("asset");
    let selectedProductionLine = form.getFieldValue("production_line");
    if (!selectedAsset && !selectedProductionLine) {
      message.error("Please enter either asset or production line");
    } else {
      const cleanedValues = prepareValues(values, typeofRec);
      try {
        const resp = await submitForm(params, cleanedValues, type);
        await handleResponse(
          resp,
          params,
          form,
          navigate,
          occDate,
          setEdit,
          setFunctions,
          type
        );
      } catch (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!!"
        );
      }
    }
  };

  const onEditClick = (status) => {
    setCurrPMStatus(status);
    if (status === "closed") {
      setSelectedPmDate(
        maintanaceDetails?.ended_at
          ? dayjs(convertUTCToLocalFormat(maintanaceDetails?.ended_at))
          : dayjs()
      );
    } else {
      setSelectedPmDate(
        maintanaceDetails?.started_at
          ? dayjs(convertUTCToLocalFormat(maintanaceDetails?.started_at))
          : dayjs()
      );
    }
    setShowDateModal(true);
  };

  const onUpdatePMDate = async (dateVal = null) => {
    let payload = { status: currPMStatus };
    if (currPMStatus === "started" && dateVal) {
      payload = {
        ...payload,
        status: "closed",
        started_at: convertToUTCFormat(dateVal),
      };
    } else if (currPMStatus === "closed" && dateVal) {
      payload = { ...payload, ended_at: convertToUTCFormat(dateVal) };
    }

    try {
      const resp = await updateOccurenceApi(params?.id, payload);
      if (resp?.data) {
        message.success(resp?.data?.message);
        refreshCall();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong!!");
    }
  };

  const verifyOcuurence = async () => {
    try {
      const resp = await updateOccurenceApi(params?.id, {
        mark_as_verified: true,
        completed: true,
      });
      message.success(resp?.data?.message);
      refreshCall();
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const handleDisabledDate = (current) => {
    if (currPMStatus === "closed" && maintanaceDetails?.started_at) {
      const startDate = dayjs(
        convertUTCToLocalFormat(maintanaceDetails?.started_at)
      );
      return (
        current &&
        (current.isBefore(startDate, "day") || current > dayjs().endOf("day"))
      );
    } else if (
      currPMStatus === "started" &&
      maintanaceDetails?.ended_at &&
      maintanaceDetails?.start_date
    ) {
      const scheduledStartDate = maintanaceDetails?.start_date;
      const endDate = dayjs(
        convertUTCToLocalFormat(maintanaceDetails?.ended_at)
      );
      return (
        current &&
        (current.isBefore(scheduledStartDate, "day") ||
          current.isAfter(endDate, "day"))
      );
    } else {
      return false;
    }
  };

  const handleDisabledTime = (current) => {
    if (currPMStatus === "closed" && maintanaceDetails?.started_at) {
      const startDate = dayjs(
        convertUTCToLocalFormat(maintanaceDetails?.started_at)
      );
      if (!startDate) {
        return {};
      }
      let endDate1 = dayjs();
      const startHours = startDate.hour();
      const startMinutes = startDate.minute();
      const endHours1 = endDate1.hour();
      const endMinutes1 = endDate1.minute();

      if (
        current &&
        current.isSame(startDate, "day") &&
        current.isSame(endDate1, "day")
      ) {
        return {
          disabledHours: () =>
            Array.from({ length: 24 }, (_, i) => i).filter(
              (hour) => hour < startHours || hour > endHours1
            ),
          disabledMinutes: (selectedHour) => {
            if (selectedHour === startHours && startHours == endHours1) {
              return Array.from({ length: 60 }, (_, i) => i).filter(
                (min) => min < startMinutes || min > endMinutes1
              );
            }
            if (selectedHour === startHours) {
              return Array.from({ length: 60 }, (_, i) => i).filter(
                (min) => min < startMinutes
              );
            }
            if (selectedHour === endHours1) {
              return Array.from({ length: 60 }, (_, i) => i).filter(
                (min) => min > endMinutes1
              );
            }
            return [];
          },
        };
      }

      if (current && current.isSame(startDate, "day")) {
        return {
          disabledHours: () => Array.from({ length: startHours }, (_, i) => i),
          disabledMinutes: (selectedHour) => {
            if (selectedHour === startHours) {
              return Array.from({ length: startMinutes }, (_, i) => i);
            }
            return [];
          },
        };
      }
      if (current && current.isSame(endDate1, "day")) {
        return {
          disabledHours: () =>
            Array.from({ length: 24 }, (_, i) => i).splice(endHours1 + 1),
          disabledMinutes: (selectedHour) => {
            if (selectedHour === endHours1) {
              return Array.from({ length: 60 }, (_, i) => i).splice(
                endMinutes1 + 1
              );
            }
            return [];
          },
        };
      }
      return {};
    } else if (
      currPMStatus === "started" &&
      maintanaceDetails?.ended_at &&
      maintanaceDetails?.start_date
    ) {
      const scheduledStartDate = maintanaceDetails?.start_date;
      const endDate = dayjs(
        convertUTCToLocalFormat(maintanaceDetails?.ended_at)
      );

      const scheduledStartHours = scheduledStartDate.hour();
      const scheduledStartMinutes = scheduledStartDate.minute();
      const endHours = endDate.hour();
      const endMinutes = endDate.minute();

      if (
        current &&
        current.isSame(scheduledStartDate, "day") &&
        current.isSame(endDate, "day")
      ) {
        return {
          disabledHours: () =>
            Array.from({ length: 24 }, (_, i) => i).filter(
              (hour) => hour < scheduledStartHours || hour > endHours
            ),
          disabledMinutes: (selectedHour) => {
            if (
              selectedHour === scheduledStartHours &&
              scheduledStartHours == endHours
            ) {
              return Array.from({ length: 60 }, (_, i) => i).filter(
                (min) => min < scheduledStartMinutes || min > endMinutes
              );
            }
            if (selectedHour === scheduledStartHours) {
              return Array.from({ length: 60 }, (_, i) => i).filter(
                (min) => min < scheduledStartMinutes
              );
            }
            if (selectedHour === endHours) {
              return Array.from({ length: 60 }, (_, i) => i).filter(
                (min) => min > endMinutes
              );
            }
            return [];
          },
        };
      }
      if (current && current.isSame(scheduledStartDate, "day")) {
        return {
          disabledHours: () =>
            Array.from({ length: scheduledStartHours }, (_, i) => i),
          disabledMinutes: (selectedHour) => {
            if (selectedHour === scheduledStartHours) {
              return Array.from({ length: scheduledStartMinutes }, (_, i) => i);
            }
            return [];
          },
        };
      }
      if (current && current.isSame(endDate, "day")) {
        return {
          disabledHours: () =>
            Array.from({ length: 24 }, (_, i) => i).splice(endHours + 1),
          disabledMinutes: (selectedHour) => {
            if (selectedHour === endHours) {
              return Array.from({ length: 60 }, (_, i) => i).splice(
                endMinutes + 1
              );
            }
            return [];
          },
        };
      }
      return {};
    } else {
      return {};
    }
  };

  const refreshCall = () => {
    getJob(
      form,
      params,
      setMaintanceDetails,
      setSopData,
      setWorkOrderID,
      setAnswers,
      setIsInspection,
      setPlId
    );
  };
  return (
    <CustomLayout
      header={
        <>
          <Header
            maintanaceDetails={maintanaceDetails}
            edit={edit}
            type={type}
            setEdit={setEdit}
            source={source}
            assetCode={assetCode}
          />
        </>
      }
      footer={
        <>
          {(!params?.id || (params?.id && edit)) && (
            <Flex justify="flex-end" gap={12}>
              <Button
                className={btnStyles.mdBtn}
                onClick={() => {
                  if (params?.id) {
                    setEdit(false);
                    getMaintanace(
                      navigate,
                      form,
                      params,
                      setTypeOfRec,
                      setWoDocuments,
                      setMaintanceDetails,
                      setSopData,
                      setAnswers,
                      occDate,
                      setOccurString,
                      setIsInspection,
                      setPlId
                    );
                  } else {
                    form.resetFields();
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className={btnStyles.mdBtn}
                type="primary"
                onClick={form.submit}
              >
                Save
              </Button>
            </Flex>
          )}
        </>
      }
    >
      <Form
        layout="vertical"
        requiredMark={false}
        form={form}
        onFinish={onFinish}
        initialValues={getIntialValues()}
        onValuesChange={(changedValues, values) =>
          handleDynamicValidations(
            changedValues,
            values,
            form,
            setOccurString,
            typeofRec
          )
        }
      >
        <Row gutter={[24, 24]}>
          <Col xs={24} md={24} lg={24} xl={16}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <GeneralDetails
                  edit={edit}
                  form={form}
                  setOccDate={setOccDate}
                  maintanaceDetails={maintanaceDetails}
                  type={type}
                  isInspection={isInspection}
                  setIsInspection={setIsInspection}
                  setSopData={setSopData}
                  productionLines={productionLines}
                  setPlId={setPlId}
                />
              </Col>
              {!params?.type && (
                <Col span={24}>
                  <Card
                    title="Recurrence"
                    styles={{
                      header: { backgroundColor: "#fff" },
                    }}
                  >
                    <Recurrence
                      occurString={occurString}
                      typeofRec={typeofRec}
                      setTypeOfRec={setTypeOfRec}
                      form={form}
                      edit={edit}
                      onFinish={onFinish}
                      maintanaceDetails={maintanaceDetails}
                      setOccurString={setOccurString}
                      type1={type}
                    />
                  </Card>
                </Col>
              )}
              {/* {params?.type &&
                (userDetails?.activeOrg?.organization?.settings
                  ?.inventory_module ||
                  userDetails?.activeOrg?.organization?.settings
                    ?.track_spare_parts) && (
                  <Col span={24}>
                    <SparePartSection
                      form={form}
                      edit={edit}
                      productionLines={productionLines}
                      plId={plId}
                      inventoryRequestData={
                        maintanaceDetails?.inventory_request
                      }
                      orderId={maintanaceDetails?._id}
                      type={"PM"}
                      refreshCall={refreshCall}
                      verifyStatus={
                        maintanaceDetails?.mark_as_verified ||
                        userDetails?.activeOrg?.role === "operator"
                      }
                      taskStatus={maintanaceDetails?.status}
                    />
                  </Col>
                )} */}
              {params?.type && (
                <Col span={24}>
                  <SparePartList
                    productionLines={productionLines}
                    plId={plId}
                    assetId={maintanaceDetails?.asset}
                    orderId={maintanaceDetails?._id}
                    type={"PM"}
                  />
                </Col>
              )}
              <Col span={24}>
                <SOPDetails
                  form={form}
                  edit={edit}
                  maintanaceDetails={maintanaceDetails}
                  sopData={sopData}
                  setSopData={setSopData}
                  answers={answers}
                  setAnswers={setAnswers}
                  isInspection={isInspection}
                  type={type}
                />
              </Col>
            </Row>
          </Col>
          {params?.id && (
            <Col xs={24} md={24} lg={24} xl={8}>
              {params?.type && (
                <MaintananceStatus
                  type={type}
                  maintanaceDetails={maintanaceDetails}
                  refreshCall={refreshCall}
                />
              )}
              {params?.type && (
                <TaskInfo
                  details={maintanaceDetails}
                  typeValue="PM"
                  showEdit={
                    maintanaceDetails?.status === "closed" &&
                    !maintanaceDetails?.mark_as_verified &&
                    verifyPM.includes(userDetails?.activeOrg?.role)
                  }
                  onEditClick={onEditClick}
                />
              )}
              {!params.type && (
                <History maintanaceDetails={maintanaceDetails} />
              )}
            </Col>
          )}

          {params?.type && (
            <Col xs={24} md={24} lg={24} xl={16}>
              <Comments
                form={form}
                workOrderID={workOrderID}
                setWorkOrderID={setWorkOrderID}
                edit={edit}
                verifyStatus={maintanaceDetails?.mark_as_verified}
              />
            </Col>
          )}
          {maintanaceDetails?.status == "closed" &&
            verifyPM.includes(userDetails?.activeOrg?.role) && (
              <Col
                xs={24}
                md={24}
                lg={24}
                xl={16}
                style={{ textAlign: "center" }}
              >
                <Button
                  size="medium"
                  style={{
                    color: maintanaceDetails?.mark_as_verified && "green",
                    width: "40vw",
                    margin: "auto",
                  }}
                  type={!maintanaceDetails?.mark_as_verified && "primary"}
                  onClick={() =>
                    !maintanaceDetails?.mark_as_verified && verifyOcuurence()
                  }
                >
                  {!maintanaceDetails?.mark_as_verified && (
                    <>
                      Mark as Verified <CheckOutlined />
                    </>
                  )}
                </Button>
              </Col>
            )}
        </Row>
      </Form>

      {showDateModal && (
        <Modal
          title=""
          open={showDateModal}
          onCancel={() => setShowDateModal(false)}
          footer={null}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            <Typography>{`${type == INSPECTION ? "Inspection" : "PM"} ${
              currPMStatus === "closed" ? "closed" : "started"
            } at`}</Typography>
            <DatePicker
              format="YYYY-MM-DD HH:mm"
              value={selectedPmDate}
              onChange={(date) => setSelectedPmDate(date)}
              placeholder="Select date"
              size="medium"
              allowClear={false}
              showTime={{
                defaultValue: dayjs("00:00", "HH:mm"),
              }}
              style={{
                width: "70%",
              }}
              disabledDate={handleDisabledDate}
              disabledTime={handleDisabledTime}
              showNow={false}
            />
            <Button
              type="primary"
              className={btnStyles.smBtn}
              style={{ float: "right", marginTop: 10 }}
              onClick={() => {
                setShowDateModal(false);
                onUpdatePMDate(selectedPmDate);
              }}
            >
              Submit
            </Button>
          </Space>
        </Modal>
      )}
    </CustomLayout>
  );
};

export default CreatePM;
