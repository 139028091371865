import axiosInstance from "../config/axios/axios.config";

export const getInventoryList = (query) => {
  return axiosInstance.get(`/inventory/all`, { params: query });
};

export const getInventoryDetails = (id) => {
  return axiosInstance.get(`/inventory/${id}`);
};

export const addInventory = (payload) => {
  return axiosInstance.post(`/inventory`, payload);
};

export const updateInventory = (id, payload) => {
  return axiosInstance.patch(`/inventory/${id}`, payload);
};

export const deleteInventoryApi = (id) => {
  return axiosInstance.delete(`/inventory/${id}`);
};

export const createInventoryRequest = (payload) => {
  return axiosInstance.post(`/inventory/requests`, payload);
};

export const updateInventoryRequest = (ids, payload) => {
  return axiosInstance.patch(
    `/inventory/requests/${ids.inventoryRequestId}/${ids.assetId}/${ids.inventoryId}`,
    payload
  );
};

export const importInventoryApi = (locationId, payload) => {
  return axiosInstance.post(
    `/inventory/bulk-upload-inventory/${locationId}`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const addStoreApi = (payload) => {
  return axiosInstance.post(`/inventory/store`, payload);
};

export const updateStoreApi = (id, payload) => {
  return axiosInstance.patch(`/inventory/store/${id}`, payload);
};

export const deleteStoreApi = (id) => {
  return axiosInstance.delete(`/inventory/store/${id}`);
};

export const getStoreListApi = (query) => {
  return axiosInstance.get(`/inventory/store/all`, { params: query });
};

export const addInventoryCategoryApi = (payload) => {
  return axiosInstance.post(`/inventory/category`, payload);
};

export const updateInventoryCategoryApi = (id, payload) => {
  return axiosInstance.patch(`/inventory/category/${id}`, payload);
};

export const deleteInventoryCategoryApi = (id) => {
  return axiosInstance.delete(`/inventory/category/${id}`);
};

export const getInventoryCategoriesListApi = (query) => {
  return axiosInstance.get(`/inventory/category/all`, { params: query });
};

export const searchStoresByInventoryApi = (query) => {
  return axiosInstance.get(`/inventory/search`, { params: query });
};

export const getStoreByInventoryApi = (id) => {
  return axiosInstance.get(`/inventory/store/${id}`);
};

//INTEND REQUEST

export const getIndentRequestListApi = (query) => {
  return axiosInstance.get(`/inventory/indent-request/all`, { params: query });
};

export const getIndentRequestDetailsApi = (id) => {
  return axiosInstance.get(`/inventory/indent-request/${id}`);
};

export const addIndentRequestApi = (payload) => {
  return axiosInstance.post(`/inventory/indent-request`, payload);
};

export const updateIndentRequestApi = (id, payload) => {
  return axiosInstance.patch(`/inventory/indent-request/${id}`, payload);
};

export const deleteIndentRequestApi = (id) => {
  return axiosInstance.delete(`/inventory/indent-request/${id}`);
};

export const getInventoryInsightsApi = (id) => {
  return axiosInstance.get(`/inventory/insights/${id}`);
};

//Allocations

export const getWOPMListApi = (query) => {
  return axiosInstance.get(`/analytics/wo-pm-list`, {
    params: query,
  });
};

export const createAllocationApi = (query, payload) => {
  return axiosInstance.post(`/inventory/allocation`, payload, {
    params: query,
  });
};

export const updateAllocationApi = (id, query, payload) => {
  return axiosInstance.patch(`/inventory/allocation/${id}`, payload, {
    params: query,
  });
};

export const getAllocationListApi = (query) => {
  return axiosInstance.get(`/inventory/allocation/all`, { params: query });
};
