import React, { useEffect, useRef, useState } from "react";
import CustomLayout from "../../Layout";
import {
  Button,
  Col,
  Empty,
  Flex,
  Result,
  Row,
  Skeleton,
  Space,
  Table,
  Typography,
  Form,
  message,
  Pagination,
  Tag,
} from "antd";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import { useNavigate } from "react-router-dom";
import {
  convertToUTCFormat,
  convertUTCToLocalFormat,
  scrollToTopTable,
  tableComponents,
} from "../../../helpers/utility";
import IssuedToForm from "./issuedToForm";
import {
  createAllocationApi,
  getAllocationListApi,
  updateAllocationApi,
} from "../../../services/inventory.services";
import dayjs from "dayjs";
import { getAllUsersApi } from "../../../services/users.services";
import ReturnToForm from "./returnToForm";
import { getItem, removeItem, setItem } from "../../../helpers/localStorage";
import { PAGESIZE } from "../../../constants/defaultKeys";

const InventoryAllocations = () => {
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerType, setDrawerType] = useState("");
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [selectedAllocation, setSelectedAllocation] = useState({});
  const [allocations, setAllocations] = useState([]);
  const [totalAllocations, setTotalAllocations] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    let locStorageData = getItem("applied_filters", true);
    if (locStorageData) setPageNumber(locStorageData.pageNumber);
  }, []);

  useEffect(() => {
    const fetchData = async (data, status = false) => {
      await fetchAllAllocations(data, status);
      scrollToTopTable(tableRef);
    };
    let locStorageData = getItem("applied_filters", true);
    if (locStorageData) {
      setPageNumber(locStorageData.pageNumber);
      fetchData(locStorageData, true);
    } else {
      fetchData({ pageNumber });
    }
  }, [pageNumber]);

  useEffect(() => {
    fetchAllUsers();
  }, [showDrawer]);

  const saveAllocationFilters = () => {
    let dataObj = {
      pageNumber: pageNumber,
    };
    setItem("applied_filters", dataObj, true);
  };

  const fetchAllAllocations = async (data, clearLocStorage) => {
    try {
      setLoading(true);
      let query = { high_value: true, page: data?.pageNumber };
      const res = await getAllocationListApi(query);
      setAllocations(
        res?.data?.allocations.map((item) => {
          // const totalUsedQuantity = item?.assets_list?.reduce(
          //   (acc, curr) => acc + curr?.used_quantity,
          //   0
          // );
          return {
            ...item,
            balance_quantity: calculateBalance(
              item?.issued_quantity,
              item?.total_used_quantity,
              item?.returned_quantity
            ),
          };
        })
      );
      setTotalAllocations(res?.data?.totalAllocations || 0);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      clearLocStorage && removeItem("applied_filters");
    }
  };

  const calculateBalance = (
    issuedQuantity = 0,
    totalUsedQuantity = 0,
    returnedQuantity = 0
  ) => {
    return issuedQuantity - totalUsedQuantity - returnedQuantity;
  };

  const tableColumns = [
    {
      title: "Store",
      dataIndex: "store",
      key: "store",
      width: 150,
      fixed: "left",
      render: (val) => <span>{val?.name ? val?.name : "-"}</span>,
    },
    {
      title: "Spare part name",
      dataIndex: "inventory",
      key: "inventory",
      width: 200,
      fixed: "left",
      render: (val) => <span>{val?.name ? val?.name : "-"}</span>,
    },
    {
      title: "Task code",
      dataIndex: "task_code",
      key: "task_code",
      width: 150,
      fixed: "left",
      render: (val, record) =>
        record?.workorder?.code ? (
          <Tag color="blue">{record?.workorder?.code}</Tag>
        ) : (
          <Space direction="vertical" style={{ gap: 2 }}>
            <Tag color="green">{record?.occurrence?.code}</Tag>
            <span style={{ fontSize: 12 }}>
              {record?.occurrence?.occurrence_date
                ? dayjs(
                    convertUTCToLocalFormat(record?.occurrence?.occurrence_date)
                  ).format("DD MMM hh:mm A")
                : "-"}
            </span>
          </Space>
        ),
    },
    {
      title: "Assets",
      dataIndex: "assets_list",
      key: "assets_list",
      width: 250,
      render: (val) => {
        return val.map((item, index) => (
          <span key={index}>
            {item?.asset?.name}
            {index !== val.length - 1 && ", "}
          </span>
        ));
      },
    },
    {
      title: "Issued to",
      dataIndex: "issued_to",
      key: "issued_to",
      width: 180,
      render: (val) => (
        <span>
          {val?.first_name} {val?.last_name}
        </span>
      ),
    },
    {
      title: "Issued date",
      dataIndex: "issued_date",
      key: "issued_date",
      width: 150,
      render: (val) => (
        <span>
          {val
            ? dayjs(convertUTCToLocalFormat(val)).format("DD MMM hh:mm A")
            : "-"}
        </span>
      ),
    },
    {
      title: "Issued quantity",
      dataIndex: "issued_quantity",
      key: "issued_quantity",
      width: 150,
      render: (val) => <span>{val || 0}</span>,
    },
    {
      title: "Total used quantity",
      dataIndex: "total_used_quantity",
      key: "total_used_quantity",
      width: 180,
      render: (val) => <span>{val || 0}</span>,
    },
    {
      title: "Returned quantity",
      dataIndex: "returned_quantity",
      key: "returned_quantity",
      width: 150,
      render: (val) => <span>{val || 0}</span>,
    },
    {
      title: "Balance quantity",
      dataIndex: "balance_quantity",
      key: "balance_quantity",
      width: 150,
      render: (val, record) => <span>{val || 0}</span>,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 200,
      render: (_, record) => (
        <Space>
          {record?.balance_quantity > 0 && (
            <Button
              size="medium"
              onClick={() => {
                setDrawerType("return");
                setShowDrawer(true);
                setSelectedAllocation(record);
              }}
            >
              Returned from
            </Button>
          )}
        </Space>
      ),
    },
  ];
  const fetchAllUsers = async () => {
    try {
      const res = await getAllUsersApi();
      setUsers(
        res?.data?.users?.map((user) => ({
          label: user.first_name + " " + (user.last_name || ""),
          value: user._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onClose = () => {
    setShowDrawer(false);
    form1.resetFields();
    form2.resetFields();
    setDrawerType("");
    setSelectedAllocation({});
    saveAllocationFilters();
  };

  const onFinishIssue = async (values) => {
    let updatedInventoryList = [];
    const inventorySet = new Set();
    for (let item of values.inventory_list) {
      if (inventorySet.has(item.inventory)) {
        message.error("Duplicate inventory are not allowed.");
        return;
      }
      inventorySet.add(item.inventory);
      updatedInventoryList.push({
        inventory: item.inventory,
        issued_quantity: item.issued_quantity,
      });
    }

    let payload = {
      ...values,
      inventory_list: updatedInventoryList,
      issued_date: values?.issued_date
        ? convertToUTCFormat(values?.issued_date)
        : "",
    };
    const taskType = values?.task_id?.split("-");

    if (taskType[0] === "wo") {
      payload["workorder"] = taskType[1];
    } else {
      payload["occurrence"] = taskType[1];
    }

    delete payload?.task_id;
    // payload["issued_date"] = convertToUTCFormat(values?.issued_date);
    payload["is_allocation"] = true;

    let query = { actionType: "issue_inventory" };

    try {
      const res = await createAllocationApi(query, payload);
      if (res) {
        message.success("Allocation created successfully");
      }
      fetchAllAllocations();
      onClose();
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const onFinishReturn = async (values) => {
    if (selectedAllocation?.balance_quantity < values?.returned_quantity) {
      message.error(
        "Returned quantity cannot be greater than balance quantity"
      );
      return;
    }
    const payload = {
      ...values,
      returned_date: values?.returned_date
        ? convertToUTCFormat(values?.returned_date)
        : "",
    };
    let query = { actionType: "return_inventory" };

    try {
      const res = await updateAllocationApi(
        selectedAllocation?._id,
        query,
        payload
      );
      if (res) {
        message.success("Allocation returned successfully");
        fetchAllAllocations();
        onClose();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <CustomLayout
      header={
        <Flex justify="space-between">
          <Typography className={typoStyles.h6}>Allocations</Typography>
        </Flex>
      }
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Table
            title={() => (
              <Flex justify="flex-end">
                <Button
                  size="large"
                  type="primary"
                  onClick={() => {
                    setDrawerType("issue");
                    setShowDrawer(true);
                  }}
                >
                  Issued to
                </Button>
              </Flex>
            )}
            size="large"
            ref={tableRef}
            columns={tableColumns}
            dataSource={allocations?.map((item) => ({
              ...item,
              key: item._id,
            }))}
            pagination={false}
            scroll={{ x: "max-content", y: "55vh" }}
            loading={
              loading
                ? { spinning: true, indicator: <Skeleton active /> }
                : false
            }
            locale={{
              emptyText: loading ? (
                <Skeleton active />
              ) : (
                <Result
                  icon={Empty.PRESENTED_IMAGE_SIMPLE}
                  title={<Typography>No allocations found</Typography>}
                />
              ),
            }}
            bordered
            components={tableComponents}
          />
        </Col>
        {totalAllocations > PAGESIZE && (
          <Col span={24} className="text-center">
            <Pagination
              current={pageNumber}
              pageSize={PAGESIZE}
              total={totalAllocations}
              onChange={(e) => setPageNumber(e)}
              showSizeChanger={false}
            />
          </Col>
        )}

        {drawerType === "issue" ? (
          <IssuedToForm
            onClose={onClose}
            showDrawer={showDrawer}
            form1={form1}
            onFinish={onFinishIssue}
            users={users}
          />
        ) : (
          <ReturnToForm
            selectedAllocation={selectedAllocation}
            showDrawer={showDrawer}
            form2={form2}
            onClose={onClose}
            onFinish={onFinishReturn}
          />
        )}
      </Row>
    </CustomLayout>
  );
};

export default InventoryAllocations;
