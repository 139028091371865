import React, { useContext, useEffect, useRef, useState } from "react";
import ChatCustomLayout from "./chatLayout";
import { Typography } from "antd";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import ChatUI from "./messageUI";
import ChatCards from "./chatComponents/chatCards";
import ChatInput from "./chatComponents/chatInput";
import "./chat.css";
import { getInsightsApi } from "../../services/ai.services";
import { UserContext } from "../../context/UserProvider";

const ChatPage = () => {
  const { userDetails } = useContext(UserContext);
  const [insightsData, setInsightsData] = useState([]);
  const [messages, setMessages] = useState([]);
  const [messageType, setMessageType] = useState("");

  const ws = useRef(null);

  useEffect(() => {
    try {
      ws.current = new WebSocket(`wss://devbolt.in/cmms-copilot/ws/`);
    } catch (error) {
      console.log(error);
    }

    ws.current.onopen = () => {
      console.log("WebSocket connection opened");
    };

    ws.current.onmessage = (event) => {
      console.log("event data", typeof event.data);
      const data = {
        sender: "Bolt",
        content: event.data,
        timestamp: new Date().toISOString(),
      };
      console.log("Received:", data);
      setMessages((prevMessages) => [...prevMessages, data]);
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.current.onclose = () => {
      console.log("WebSocket connection closed");
    };

    // Cleanup on unmount
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  const sendMessage = (messageContent) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      const message = {
        sender: userDetails?.first_name || "Anonymous",
        content: messageContent,
        timestamp: new Date().toISOString(),
      };
      ws.current.send(JSON.stringify(message));
      setMessages((prevMessages) => [...prevMessages, message]);
      setMessageType("text");
    } else {
      console.error("WebSocket is not open");
    }
  };

  const getInsights = async () => {
    try {
      const resp = await getInsightsApi(
        userDetails?.activeOrg?.organization?._id
      );
      console.log(resp);
      setMessageType("insights");
      setInsightsData(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ChatCustomLayout
      header={<Typography className={typoStyles.h6}>Chat </Typography>}
    >
      <div className="chat-container">
        <ChatUI
          insightsData={insightsData}
          messageType={messageType}
          setMessageType={setMessageType}
          messages={messages}
        />
        <div style={{ display: "flex", flexDirection: "column", gap: "22px" }}>
          <ChatCards getInsights={getInsights} />
          <ChatInput sendMessage={sendMessage} />
        </div>
      </div>
    </ChatCustomLayout>
  );
};

export default ChatPage;
